import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FieldLockService {
    private fields: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();

    constructor() {}

    public setLockedFields(fieldList: string[]) {
        this.fields.clear();
        fieldList.forEach(f => {
            this.fields.set(f, new BehaviorSubject<boolean>(true));
        })
    }

    public getLockedFields(): string[] {
        return Array.from(this.fields.entries()).filter(([name, sub]) => sub.getValue()).map(([name, sub]) => name);
    }

    public lockField(fieldToLock: string) {
        if (this.fields.has(fieldToLock)) {
            this.fields.get(fieldToLock)?.next(true);
        } else {
            this.fields.set(fieldToLock, new BehaviorSubject<boolean>(true));
        }
    }

    public unlockField(fieldToUnlock: string) {
        if (this.fields.has(fieldToUnlock)) {
            this.fields.get(fieldToUnlock)?.next(false);
        } else {
            this.fields.set(fieldToUnlock, new BehaviorSubject<boolean>(false));
        }
    }

    public isLocked(fieldToCheck: string) {
        if (!this.fields.has(fieldToCheck)) {
            return false;
        }

        return this.fields.get(fieldToCheck)!.getValue();
    }
}