import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ClientBranch } from "../models/clientBranch.model";
import { CustomerAccountInfo } from "../definitions";

@Injectable()
export class ClientBranch_Service extends ResourceService<ClientBranch> {
    protected servicePath = '/ClientBranch';

    public ModelType = ClientBranch;

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getServiceUrl(): string {
        return this.serviceUrl
    }

    public findByCompanyNumberAndCorpId(companyNumber: string, corpId: string): Observable<ClientBranch[]> {
        console.debug('ClientBranch_Service.findByCompanyNumberAndCorpId', companyNumber, corpId);
        //return of([]);
        if (!companyNumber || !corpId) {
            return of([]);
        }
        return this.http.get<ClientBranch[]>(`${this.serviceUrl}findByCompanyNumberAndCorpId/${companyNumber}/${corpId}`)
            .pipe(
                map(results => {
                    return results.map(o => new ClientBranch(o))
                })
            );
    }

    public findByCompanyNumberCustomerNumberAndCorpId(companyNumber: number, customerNumber: number, corpId: string): Observable<ClientBranch[]> {
        console.debug('ClientBranch_Service.findByCompanyNumberCustomerNumberAndCorpId', companyNumber, customerNumber, corpId);
        if (!companyNumber || !customerNumber || !corpId) {
            return of([]);
        }

        const body = {
            companyNumber: companyNumber,
            customerNumber: customerNumber,
            corpId: corpId
        }
        return this.http.post<ClientBranch[]>(`${this.serviceUrl}findByCompanyNumberCustomerNumberAndCorpId`, body)
            .pipe(
                map(results => {
                    return results.map(o => new ClientBranch(o))
                })
            );
    }

    public delete(branch: ClientBranch) {
        return this.http.delete<ClientBranch>(`${this.serviceUrl}${branch.uuid}`);
    }

    public update(branch: ClientBranch) {
        return this.http.put<ClientBranch>(`${this.serviceUrl}${branch.uuid}`, branch);
    }

    public getByDefaultClientMatterSetId(setId: string) {
        return this.http.get<ClientBranch[]>(`${this.serviceUrl}getByDefaultClientMatterSetId?uuid=${setId}`);
    }

    public getCustomerAccountsByCorpId(corpId: string) {
        return this.http.get<CustomerAccountInfo[]>(`${this.serviceUrl}getCustomerAccountsByCorpId?corpId=${corpId}`);
    }

    public getBillingAccounts(clientId: string) {
        return this.http.get<ClientBranch[]>(`${this.serviceUrl}getBillingAccounts?clientId=${clientId}`);
    }

    public getByClientId(clientId: string) {
        return this.http.get<ClientBranch[]>(`${this.serviceUrl}getByClientId?clientId=${clientId}`);
    }

    public getPageForConfig(batchSize: number, query: string) {
        return this.http.get<ClientBranch[]>(`${this.serviceUrl}getByName?batchSize=${batchSize}&query=${query}`);
    }

    public makeLookupConfig() {
        const service = this;
        let identifier = (type: ClientBranch) => type && type.uuid;
        let lookup = (id: string) => service.get(id);
        let display = (type: ClientBranch) => {
            if (!type)
                return '';

            return `${type.name}`
        };
        let suggest = (term: string, limit?: number) => {
            if (!term)
                return of([]);

            return service.getPageForConfig(limit || 50, term);
        }
        return { identifier, lookup, display, suggest };
    }
}