import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export enum AddressPurpose {
	local = 'local',
	billing = 'billing',
	person = 'person'
}
export enum AddressType {
	COURT = 'Court',
	ClientBranch = 'ClientBranch'
}

export class AddressBook extends DexResourceModel {
    /**
     * The address's nickname
     */
    @Field(String)
	public name: string = "";
    /**
     * the SuccessAccount that owns this address
     */
    @Field(String)
    public owner_uuid: string | null = null;

	/**
	 * The clientBranch that owns this address
	 * Addresses of type 'ClientBranch' will have a clientBranch_uuid and purpose; otherwise these two fields are typically null
	 */
	@Field(String)
	public clientBranch_uuid: string | null = null;

    /**
     * The organization/firm that owns this address
     */
    @Field(String)
    public corp_id: string | null = null;
    /**
     * The preferred or default address to choose
     */
    @Field(Boolean)
    public isDefault: boolean = false;

    @Field(String)
    public streetAddress1: string = "";
    @Field(String)
    public streetAddress2: string | null = null;
    @Field(String)
    public roomNumber: string = "";
    @Field(String)
    public postalCode: string = "";
    @Field(String)
    public county: string | null = null;
    @Field(String)
    public state: string = "";
    @Field(String)
    public city: string = "";
    @Field(String)
    public country: string = "US";
    @Field(String)
    public careOfLine: string = "";
    @Field(String)
    public primaryPhoneNumber: string = "";
    @Field(String)
    public primaryFaxNumber: string = "";
    @Field(Boolean)
    public isPrivate: boolean = false;
    @Field(String)
    public addressType: AddressType | string = AddressType.ClientBranch;
	@Field(String)
    public addressPurpose: AddressPurpose | null = null;
    @Field(String)
    public jurisdiction: string | null = null;
    @Field(Number)
    public phoneExt: number | null = null;
    @Field(String)
    public syncLocks: string | null = null;


    get active(): boolean {
        return this.deletedAt == null;
    }
    set active(active: boolean) {
        if (active) {
            this.deletedAt = null;
        } else {
            this.deletedAt = new Date();
        }
    }

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
