


import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PDFObject } from "../models/pdfObject.model";
import * as PDFJS from "pdfjs-dist"
import "pdfjs-dist/build/pdf.worker.entry";
// import JSZIP from 'jszip'

const isAlphaNumeric = (str: string) => /^[a-z0-9]*$/gi.test(str);

@Injectable()
export class PDFAnalysisTool_service {
    private _documentList: PDFObject[] = [];
    public documentList: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(){}

    get documentList$() {
        return this._documentList;
    }

    set documentList$(docList: PDFObject[])
    {
        this._documentList = docList;
        for(const pdfObj of docList)
            this.documentList.next(pdfObj);
    }

    public addDocuments(docList: PDFObject[]){
        this._documentList = this._documentList.concat(docList);
        for(const pdfObj of docList)
            this.documentList.next(pdfObj);
        
        if(docList.length)
            this.processPDFList(docList)
    }

    // public async addZip(zip: File){
    //     const z = new JSZIP();
    //     z.loadAsync(zip).then(async unzip => {
    //         console.log("uz?", unzip);
    //         await Object.keys(unzip.files).forEach(fileName => {
    //             if(fileName){
    //                 console.log("fn:", fileName);
    //                 if(unzip.files[fileName]){
    //                     unzip.files[fileName].async('blob').then(fileBlob => {
    //                         console.log("blob:", fileBlob);
    //                         if(fileName.match(/\.pdf$/)){
    //                             console.log("valid pdf:", fileName, fileBlob);
    //                             let pdfObj = new PDFObject(new File([fileBlob], fileName));
    //                             this._documentList.push(pdfObj);
    //                             this.processPDFInfo(pdfObj);
    //                             this.renderThumbnails(pdfObj);
    //                         }
    //                         const feelay: File = new File([fileBlob], fileName)
    //                         console.log("feelay:", feelay);
    //                         if(fileBlob.type == "application/pdf"){
    //                             console.log("Valid PDF:", fileBlob);
    //                         }
    //                     })
    //                 }
    //             }
    //         });
    //     })
    // }

    private async processPDFList(docList: PDFObject[]){
        let index = this._documentList.length - docList.length;
        docList.forEach(async (pdfObj: PDFObject) => {
            let idx = index++;
            await pdfObj.document.arrayBuffer().then( async ab => {
                await PDFJS.getDocument(new Uint8Array(ab)).promise.then( async pdf => {
                    pdf.getMetadata().then( meta => {
                        let metaData = meta as any;
                        
                        if(metaData.info?.Title)
                            this._documentList[idx].title = metaData.info.Title;
                        else
                            this._documentList[idx].title = this.generatePseudoTitle(pdfObj.document.name);
                        
                        if(pdf.numPages)
                            this._documentList[idx].pageCount = pdf.numPages;
                        else
                            this._documentList[idx].pageCount = 0;

                        let textSegments: string[] = [];
                        pdf.getPage(1).then( res => {
                            res.getTextContent().then( async rr => {
                                rr.items.forEach( (s: any) => {
                                    if(s.str){
                                        if(s.str != "" && s.str != " ")
                                            textSegments.push(s.str);
                                    }
                                });
                                let caseInfo : string[] = this.parseCaseInfo(textSegments);
                                this._documentList[idx].plaintiff = caseInfo[0];
                                this._documentList[idx].defendant = caseInfo[1];
                                this._documentList[idx].caseName  = caseInfo[2];
                                this._documentList[idx].caseNo = this.parseCaseNo(textSegments);
                                this._documentList[idx].processed = true;
                                this.documentList.next(this._documentList[idx]);
                                await pdf.cleanup();
                                this.renderThumbnails(this._documentList[idx]);
                            });

                        })
                    })
                })
                .catch(err => {
                    console.log("Error while processing:", pdfObj.document.name);
                    console.log("Error: ", err);
                    this._documentList[idx].corrupted = true;
                    this.documentList.next(this._documentList[idx]);
                });
            });
        });
    }

    private parseCaseInfo(text: string[], limit: number = 10): string[] {
        let idx: number = 0;
        let plaintiffIdx = -1;
        let vsIdx = -10;
        let defendantIdx = -100;
        let caseIdx: number[] = [];
        while(idx < text.length){
            if(text[idx].toLowerCase().indexOf("plaintiff") > -1)
                plaintiffIdx = idx;
            else if(text[idx].toLowerCase().indexOf("vs") > -1)
                vsIdx = idx;
            else if(text[idx].toLowerCase().indexOf("defendant") > -1){
                defendantIdx = idx;
                if(vsIdx > plaintiffIdx && vsIdx < defendantIdx && defendantIdx - plaintiffIdx < limit){
                    caseIdx = [plaintiffIdx, vsIdx, defendantIdx];
                    break;
                }
            }
            idx++;
        }
        if(caseIdx.length){
            let plaintiffString = text[caseIdx[0] - 1];
            let plaintiff = "";
            if(plaintiffString.length < 2 && !isAlphaNumeric(plaintiffString) && caseIdx[0] - 2 > -1)
                plaintiffString = text[caseIdx[0] - 2];
            [...plaintiffString].forEach( letter => {
                if(isAlphaNumeric(letter) || letter == ' ')
                    plaintiff += letter;
            })
            let vsIdx = caseIdx[1];
            let defIdx = caseIdx[2];
            let defString = "";
            let defendant = "";
            while(++vsIdx < defIdx)
                defString += text[vsIdx] + " ";
            [...defString].forEach( letter => {
                if(isAlphaNumeric(letter) || letter == ' ')
                    defendant += letter;
            })
            if(!plaintiff.length)
                plaintiff = "N/A"
            if(!defendant.length)
                defendant = "N/A"
            return [
                plaintiff.trim(),
                defendant.trim(),    
                (plaintiff.length && defendant.length) ? `${plaintiff.trim()} vs ${defendant.trim()}` : "N/A"
            ]
        }
        return [];
    }

    private parseCaseNo(text: string[]): string | null {
        let idx = -1;
        let caseNoIdx: number[] = [];
        while(++idx < text.length){
            let wordIdx = text[idx].toLowerCase().indexOf("case no")
            if(wordIdx > -1)
                caseNoIdx = [idx, wordIdx];
        }
        if(caseNoIdx[0] > -1){
            let caseNoString = text[caseNoIdx[0]].slice(caseNoIdx[1] + 8);
            let caseNo = "";
            [...caseNoString].forEach( letter => {
                if(isAlphaNumeric(letter) || letter == ' ' || letter == '-')
                    caseNo += letter;
            })
            if(!caseNo.trim()){
                caseNo = "";
                if(caseNoIdx[0] + 1 < text.length){
                    caseNoString = text[caseNoIdx[0] + 1];
                    [...caseNoString].forEach(letter => {
                        if(isAlphaNumeric(letter) || letter == ' ' || letter == '-')
                            caseNoString += letter;
                    })
                }
            }
            if(caseNo.trim())
                return caseNo.trim();
        }
        return null;
    }

    private generatePseudoTitle(title: string): string{
        let pureTitle = title.trim().slice(0, title.length - 4);
        let pseudoTitle = "";
        let spacer: boolean = true;
        [...pureTitle].forEach( ch => {
            if(isAlphaNumeric(ch)){
                spacer = false;
                pseudoTitle += ch;
            }
            else if(!spacer){
                pseudoTitle += " ";
                spacer = true;
            }
        })
        return pseudoTitle.trim();
    }

    public async processPDFInfo(PDFObj: PDFObject) : Promise<boolean> {
        let idx = -1;
        for(let i = 0; i < this._documentList.length; i++){
            if(PDFObj.addDate == this._documentList[i].addDate && PDFObj.document.name == this._documentList[i].document.name){
                idx = i;
                break;
            }
        }
        if(idx > -1){
            await PDFObj.document.arrayBuffer().then( async ab => {
                await PDFJS.getDocument(new Uint8Array(ab)).promise.then( pdf => {
                    pdf.getMetadata().then( meta => {
                        let metaData = meta as any;
                        
                        if(metaData.info?.Title)
                            this._documentList[idx].title = metaData.info.Title;
                        else
                            this._documentList[idx].title = this.generatePseudoTitle(PDFObj.document.name);
                        
                        this._documentList[idx].pageCount = pdf.numPages;
                        let textSegments: string[] = [];
                        pdf.getPage(1).then( res => {
                            res.getTextContent().then( async rr => {
                                rr.items.forEach( (s: any) => {
                                    if(s.str){
                                        if(s.str != "" && s.str != " ")
                                            textSegments.push(s.str);
                                    }
                                });

                                let caseInfo : string[] = await this.parseCaseInfo(textSegments);
                                this._documentList[idx].plaintiff = caseInfo[0];
                                this._documentList[idx].defendant = caseInfo[1];
                                this._documentList[idx].caseName  = caseInfo[2];
                                this._documentList[idx].caseNo = await this.parseCaseNo(textSegments);
                                this._documentList[idx].processed = true;
                                this.documentList.next(this._documentList[idx]);
                                pdf.cleanup();
                            });

                        })
                    });
                })
                .catch(err => {
                    this._documentList[idx].corrupted = true;
                    this.documentList.next(this._documentList[idx]);
                    return true;
                });
            });
            return true;
        }
        return false;
    }


    public async renderThumbnails(PDFObj: PDFObject){
        let idx = -1;
        for(let i = 0; i < this._documentList.length; i++){
            if(PDFObj.addDate == this._documentList[i].addDate && PDFObj.document.name == this._documentList[i].document.name){
                idx = i;
                break;
            }
        }
        if(idx > -1) {
            this._documentList[idx].document.arrayBuffer().then( r => {
                PDFJS.getDocument(r).promise.then( async pdf => {
                    let thumbnails: (Blob | null)[] = [null, null, null];
                    for(let pageNum = 1; pageNum < 4 && pageNum <= pdf.numPages; pageNum++){
                        let canvas: HTMLCanvasElement = document.createElement("canvas");
                        let ctx = canvas.getContext("2d");
                        if(!ctx){
                            thumbnails[pageNum - 1] = null;
                            continue;
                        }
                        let page = await pdf.getPage(pageNum);
                        let viewPort = await page.getViewport({scale: 1});
                        canvas.width = viewPort.width;
                        canvas.height = viewPort.height;
                        await page.render({canvasContext: ctx, viewport: viewPort}).promise.then(() => { 
                            canvas.toBlob(blob => { 
                                thumbnails[pageNum - 1] = blob;
                                if(pageNum + 1 >= 4 || pageNum + 1 > pdf.numPages){
                                    this._documentList[idx].thumbnails = thumbnails;
                                    this._documentList[idx].rendered = true;
                                    this.documentList.next(this._documentList[idx]);
                                }
                            })
                        });
                    }
                    await pdf.cleanup();
                })
            })
            return true;
        }
        return false;
    }

    public checkPdfObj(PDFObj: PDFObject){
        for(let pdfo of this._documentList)
            if(pdfo.addDate == PDFObj.addDate && pdfo.document.name == PDFObj.document.name)
                return pdfo;
        return null;
    }

    public removeDocuments(index: number){
        let removed: PDFObject = this._documentList.splice(index, 1)[0];
        removed.removed = true;
        this.documentList.next(removed);
    }

    public clearDocuments(){
        this._documentList = [];
        this.documentList.next(null);
    }

}