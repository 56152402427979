import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export enum OrderStatus {
    DRAFT = "Draft",
    PROCESSING = "Processing",
    ACCEPTED = "Accepted",
    REJECTED = "Rejected",
    CANCELED = "Canceled",
	PENDING = "Awaiting Documents",
	IMPORTED = "Imported",
	STUB = "Stub"
}

export class Order extends DexResourceModel {
	@Field(String)
	public orderId: number = 0;
	@Field(String)
	public case_uuid?: string | null = null;
	@Field(String)
	public caseNumber?: string | null = null;
	@Field(String)
	public caseName?: string | null = null;
	@Field(String)
	public filingServiceType_uuid?: string | null = null;
	@Field(String)
	public documentFolder?: string | null = null;
	@Field(String)
	public name?: string | null = null;
	@Field(String)
	public orderType?: string | null = null;
	@Field(String)
	public orderStatus?: string | null = null;
	@Field(String)
	public errorMessage?: string | null = null;
	@Field(String)
	public courtName?: string | null = null;
	@Field(String)
	public courtAddress?: string | null = null;
	@Field(String)
	public courtCity?: string | null = null;
	@Field(String)
	public courtState?: string | null = null;
	@Field(String)
	public courtZip?: string | null = null;
	@Field(Boolean)
	public advancedFees?: boolean | null = null;
	@Field(Boolean)
	public firstAppearanceFees?: boolean | null = null;
	@Field(String)
	public customerNumber?: string | null = null;
	@Field(String)
	public companyNumber?: string | null = null;
	@Field(String)
	public placedByEmail?: string | null = null;
	@Field(String)
	public orderData?: string | null = null;
	@Field(String)
	public clientMatterNumber?: string | null = null;
	@Field(String)
	public claimNumber?: string | null = null;
	@Field(String)
	public orderDate?: Date | null = null;
	@Field(String)
	public jobDate?: Date | null = null;
	@Field(String)
	public dueDate?: Date | null = null;
	@Field(String)
	public vendor_uuid?: string | null = null;
	@Field(String)
	public vendorId?: string | null = null;
	@Field(String)
	public vendorStatus?: string | null = null;
	@Field(String)
	public displayStatus?: string | null = null;
	@Field(String)
	public vendor_siteURL?: string | null = null;
	@Field(String)
	public vendor_returnedDocumentPath?: string | null = null;
	@Field(Date)
	public vendor_lastUpdatedAt?: Date | null = null;
	@Field(String)
	public serviceType?: string | null = null;
	@Field(String)
	public corpId?: string | null = null;
	@Field(String)
	public vendorKeyPart?: string | null = null;
	@Field(String)
	public successAccount_uuid?: string | null = null;
	@Field(String)
	public documentsDescription?: string | null = null;
	@Field(String)
	public pickup_ServiceAddress_uuid: string | null = null;
	@Field(String)
	public delivery_ServiceAddress_uuid: string | null = null;
	@Field(String)
	public insuranceCompany: string | null = null;
	@Field(String)
	public insuranceAdjuster: string | null = null;
	@Field(Date)
	public resyncRequestedAt?: Date | null = null;
	@Field(Number)
	public serviceTypeNumber: number = 0;  
	@Field(Boolean) 
	public advanceFees: boolean = false;
	@Field(Boolean) 
	public alwaysDeliverCourtesyFeesBack = false;
	@Field(Boolean) 
	public hasNoContactHours = false;
	@Field(Number) 
	public noContactFromTime: number | null = null;
	@Field(Number) 
	public noContactToTime: number | null = null;
	@Field(String)
	public sourceVendor_uuid: string | null = null;
	@Field(String)
	public sourceVendorId: string | null = null;

	
	// these fields are not defined on the table proper, but are needed for the list page.
	// determined by Vendor 
	@Field(String)
	public division?: string | null = null;
	// composite of 'firstName' and 'lastName' from Success Account
	@Field(String)
	public requestedBy?: string | null = null;
	// composite field of 'vendorId' and 'companyNumber' or 'vendorKeyPart' or nothing 
	@Field(String)
	public orderNumber?: string | null = null;
	// composite of 'firstName' and 'lastName' from 'ToServe'
	@Field(String)
	public subject?: string | null = null;
	// 'name' from 'ServiceAddress' table 
	@Field(String)
	public deliveryLocation?: string | null = null;
	// composite of 'address', 'city', 'state', and 'zip' from 'ServiceAddress' table
	@Field(String)
	public deliveryAddress?: string | null = null;
	// composite of 'address', 'city', 'state', and 'zip' from 'ServiceAddress' table
	@Field(String)
	public pickupAddress?: string | null = null;
	// composite of 'address', 'city', 'state', and 'zip' from 'ServiceAddress' table
	@Field(String)
	public divisionExtended?: string | null = null;

	public createdAt: any | null = null;
	public deletedAt: any | null = null;
	public updatedAt: any | null = null;

	constructor(data?: any) {
        super();
        this.mapData(data);
    }

	public get vendorDateUTC(): Date | null{
		if(!this.vendor_lastUpdatedAt) return null;
        return this.pacificDateAsUTC(this.vendor_lastUpdatedAt);
    }

	public get orderDateUTC() : Date | null {
		if(!this.orderDate) return null;
        return this.pacificDateAsUTC(this.orderDate);
    }

	public get jobDateUTC() : Date | null {
		if(!this.jobDate) return null;
        return this.pacificDateAsUTC(this.jobDate);
    }

	public get dueDateUTC() : Date | null {
		if(!this.dueDate) return null;
        return this.pacificDateAsUTC(this.dueDate);
    }

    public get data(): any {
        let json = super.data;
        return json;
    }
}
