import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable()
export class UserDevicePreferencesService  {

    private reflexOptions: {[key:string]:any} = {};
    private fcOptions: {[key:string]:any} = {};

    constructor()
    {

    }

    public get reflexFilter() {
        return this.reflexOptions;
    }

    public set reflexFilter(filterObject: any) {
        let obj = this.reflexOptions;
        Object.keys(filterObject).map((filter)=>{obj[filter] = filterObject[filter]});
        this.reflexOptions = obj;
    }

    public get fcFilter() {
        return this.fcOptions;
    }

    public set fcFilter(filterObject: any) {
        let obj = this.fcOptions;
        Object.keys(filterObject).map((filter)=>{obj[filter] = filterObject[filter]});
        this.fcOptions = obj;
    }

    public getLocal(){
        let filters = window.localStorage.getItem('filterOptions');
        let objectBody = filters ? JSON.parse(filters) : { 
            'reflex': {}, 
            'fc3': {} 
        }
        this.fcFilter = objectBody.fc3;
        this.reflexFilter = objectBody.reflex;
    }

    public editLocal(){
        let objectBody = {
            'reflex': this.reflexFilter,
            'fc3': this.fcFilter
        }
        window.localStorage.setItem('filterOptions', JSON.stringify(objectBody));
    }
}
