import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class InvoiceLineItem extends DexResourceModel {

    @Field(String)
    public invoice_uuid: string | null = null;
    @Field(String)
    public order_uuid: string | null = null;
    @Field(String)
    public itemLabel: string = "";
    @Field(Number)
    public quantity: number | null = null;
    @Field(Number)
    public rate: number | null = null;
    @Field(Number)
    public revenue: number | null = null;
    @Field(Number)
    public nonrevenue: number | null = null;
    @Field(Number)
    public commissionable: number | null = null;
    @Field(Number)
    public jobTotal: number | null = null;
    @Field(Number)
    public lineTotal: number | null = null;
    @Field(String)
    public syncLocks: string | null = null;
    @Field(Number)
    public tax: number | null = null;


    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
