import { Field } from "@smartsoftware/reflex-core";
import { DexResourceModel } from "../abstracts";

export class Document extends DexResourceModel {

    // Used in place of filename
    // for cleaned up version for saving to S3
    @Field(String)
    public name: string | null = null;

    //declared already on ResourceModel
    // @Field(String)
    // public status: string | null = null;
    public docStatus: string | null = null;

    @Field(String)
    public fileType: string | null = null;

    @Field(String)
    public fileName: string | null = null;

    @Field(String)
    public vendorFileName: string | null = null;

    @Field(String)
    public documentType: string | null = null;

    @Field(Boolean)
    public isRelayed: Boolean = false;

	@Field(String)
    public vendor_id: string | null = null;

    @Field(String)
    public upsert_order_id: string | null = null;

    @Field(String)
	public source_document_uuid: string | null = null;

	@Field(String)
    public documentIdentifier: string | null = null;
    
    @Field(String)
    public lcDocumentType: string | null = null;

    @Field(BigInt)
    public size: BigInt | null = null; 

    @Field(String)
    public downloadURL: string | null = null

    get data(): any {
        let d = super.data;
        d.status = this.docStatus;
        return d;
    };

    protected mapData(data?: any): void {
        super.mapData(data);
        this.docStatus = data?.status;
    }

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public getCleanedS3Name(fileName: string): string{
        if(!fileName){
            return fileName;
        }
        //filename patterns
        console.debug(fileName)
        let fileExtensionIndex = fileName.lastIndexOf('.');
        let cleanName = (fileExtensionIndex > 0) ? fileName.substring(0, fileExtensionIndex) : fileName;
        // Remove characters on s3 avoid list
        console.debug("Original:", fileName)
        cleanName = cleanName.replace(/[\\{^}%\]>\[~<#|]+/g, '');
        console.debug("Avoid List:", cleanName)
        // Remove quotes
        cleanName = cleanName.replace(/[`'"]+/g, '');
        console.debug("Quote List:", cleanName)
        // Potentially remove
        cleanName = cleanName.replace(/[&$@=;/:+,?]+/g, '');
        console.debug("Potential List:", cleanName, cleanName.length, cleanName.length == 0)

        // Characters FL specifically asked to be removed
        cleanName = cleanName.replace(/§/g, '');
        console.debug("FL List:", cleanName, cleanName.length, cleanName.length == 0);

        if(cleanName.length == 0){
            cleanName = 'PlaceHolderFileName' + cleanName;
        }
        let suffix = (fileExtensionIndex > 0) ? fileName.substring(fileExtensionIndex) : '';
        console.debug("Final Name:", cleanName + suffix);
        return cleanName + suffix;
    }

}