import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { SequenceOrderStepType } from '../models';

@Injectable()
export class SequenceOrderStepType_Service extends ResourceService<SequenceOrderStepType> {
    protected servicePath: string = '/SequenceOrderStepType';

    public ModelType = SequenceOrderStepType;
    
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
