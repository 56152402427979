import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { SequenceOrder } from '../models';

@Injectable()
export class SequenceOrder_Service extends ResourceService<SequenceOrder> {
    protected servicePath: string = '/SequenceOrder';

    public ModelType = SequenceOrder;
    
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
