
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ResourceService } from '@smartsoftware/reflex-core';
import { Role } from '../models/role.model';
import { SuccessAccount } from '../models/successAccount.model';
import { PermissionNode } from '../models/permissionNode.model';
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from 'rxjs/operators';
import { Page, PageParams } from '../paged.datasource';

@Injectable()
export class PermissionNode_Service extends ResourceService<PermissionNode> {

    protected servicePath = '/PermissionNode';

    private _permissions: PermissionNode[] = [];
    get permissions(): PermissionNode[] {
        return this._permissions;
    }
    set permissions(data: PermissionNode[]) {
        this._permissions = data;
    }

    private _isDataSynced = false;
    get isDataSynced(): boolean {
        return this._isDataSynced;
    }
    set isDataSynced(status: boolean) {
        this._isDataSynced = status;
        this.dataSync.next(this._isDataSynced);
    }

    public ModelType = PermissionNode;
    public dataSync: BehaviorSubject<boolean> = new BehaviorSubject(this._isDataSynced);

    constructor(
        protected http: HttpClient
    ) {
        super(http);
        this.syncPermissionData();
    }
  
    public syncPermissionData(){
        this.allPermissionNodes()
        .then(
            (response: any) => {
                this.isDataSynced = true;
            }
        )

    }

    page(pageParams: PageParams): Observable<Page<PermissionNode>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
    
        return this.http.get<Page<PermissionNode>>(`${this.serviceUrl}successPage`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new PermissionNode(i));
                return page;
            }));
    }


    public allPermissionNodes(): any {
        return this
            .http
            .get(
                this.serviceUrl,
                {
                    observe: 'response'
                }
            )
            .pipe(
                tap((response: any) => {
                    for(let x in response.body)
                    {
                        if(response.body[x].deletedAt == null){
                            this.permissions.push(response.body[x])
                        }
                    }
                })
            )   
            .toPromise();
    }

    public getClientPermissionNodes(): Observable<PermissionNode[]> {
        return this
            .http
            .get(
                this.serviceUrl + 'getClientPermissionNodes',
                {
                    observe: 'response'
                }
            )
            .pipe(
                map((response: any) => {
                    if(response && response.body) {
                        return response.body;
                    } else {
                        return [];
                    }
                })
            )   
    }

    
    public push(entry: PermissionNode): Observable<PermissionNode> {
        
        if(!entry.description) {
            entry.description = "";
        }

        if(!entry.internal){
            entry.internal = false;
        }
        
        if(!entry.code){
            entry.code = "";
        }
        
        return super.push(entry);
    }

    public deletePermissionNode(permissionNode: PermissionNode) {
        return this
            .http
            .post(
                this.serviceUrl + 'deletePermissionNode',
                {
                    permissionNode: permissionNode
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: number) => e)
                )
            );   
    }

}
