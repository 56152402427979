import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ResourceListComponent, ResourceListDataSource, ResourceListPane } from '@smartsoftware/reflex-core';
import { MatSort } from '@angular/material/sort';

import { 
    SuccessAccount, 
    SuccessAccountFirm_Service,
    SuccessAccount_Service, 
    SystemConfig_service, 
    UserPermission_Service ,
    PagedResourceListComponent,
    SuccessAccountDataSource,
    UserDevicePreferencesService
} from 'legalreflex-lib';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FormBuilder } from '@angular/forms';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'success-account-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class SuccessAccountList extends PagedResourceListComponent<SuccessAccount> {

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('filter') filterPane: ResourceListPane = new ResourceListPane();
    public filterCollapsed: boolean = sessionStorage.getItem("filterCollapsed") == 'true';
    public isLoading: boolean = true;
    public userCorp: string = '';
    public userBranch: string = '';
    public userCompany: number = 0;
    public userCustomer: number = 0;
    public showCompany: boolean = false;
    public showCustomer: boolean = false;
    public columns: string[] = [
       'View', 'username', 'email', 'firstName', 'lastName', 'companyNumber', 'customerNumber'
    ];


    constructor(
        public dataSource: SuccessAccountDataSource,
        protected userPermissionService: UserPermission_Service,
        protected resourceService: SuccessAccount_Service,
        protected userDevicePreferences: UserDevicePreferencesService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected dialog: MatDialog,
        protected fb: FormBuilder,
        private breadCrumbService: BreadCrumbService,
        public sysConfigService: SystemConfig_service,
        protected auth: OidcSecurityService,
    ) {
        super(dataSource, resourceService, dialog, route, router);
        this.sort = new MatSort()
        this.setBreadCrumbs();

        let obj = {
            firstName: [''],
            lastName: [''],
            email: [''],
            username: [''],
            corpId: [''],
            customerNumber: [''],
            companyNumber: [''],
            deletedAt: [''],
            primaryClientBranch_uuid: ['']
        }
        this.filterFormGroup = this.fb.group(obj)
    }

    setBreadCrumbs(){
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Users', url: '/users'}
        ]);
        let actions: any[] = [{ label: 'Refresh', action:this.filterSubmit.bind(this), icon:'refresh'}]
        if(this.showCompany && this.showCustomer){
            actions.push({ label: 'New User', routerLink: ['/users/new'], icon:'library_add'})
        }
        this.breadCrumbService.actions.next(actions);
    }

    pageChange(event?:any){
        this.selection.clear();
        this.setBreadCrumbs();
    }

    sortData(event: any){
        if(this.dataSource.sort){
            this.dataSource.sort.active = event.active
            this.dataSource.sort.direction = event.direction            
        }
        this.dataSource.reload();
    }

    ngOnInit(): void {
        super.ngOnInit();
        if(this.sysConfigService.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };

        setTimeout(()=>{
            this.auth.isAuthenticated$.subscribe((authenticated) => {
                this.auth.userData$.pipe(filter(ls => !!ls.userData.profile)).subscribe((user)=> {
                    if(user.userData.profile.uuid && user.userData.profile.internalUser){
                        this.showCompany = true;
                        this.showCustomer = true;
                        this.userCustomer = user.userData.profile.customerNumber
                        this.userCompany = user.userData.profile.companyNumber

                        let obj = {
                            firstName: [''],
                            lastName: [''],
                            email: [''],
                            username: [''],
                            corpId: [''],
                            customerNumber: [''],
                            companyNumber: [''],
                            deletedAt: [null],
                            primaryClientBranch_uuid: ['']
                        }
                        this.filterFormGroup = this.fb.group(obj)
                        this.filterSubmit();

                    } else {
                        let foundFirm = this.userPermissionService.loggedInUserRoles.find((role)=> role.name == 'Client Firm Administrator');
                        let foundOffice = this.userPermissionService.loggedInUserRoles.find((role)=> role.name == 'Client Office Administrator');
                        
                        if(foundFirm){
                            this.showCompany = true;
                            this.showCustomer = true;
                        } else if(foundOffice){
                            this.showCustomer = true;
                        } 
                        this.userCustomer = user.userData.profile.customerNumber ? user.userData.profile.customerNumber : this.userCustomer
                        this.userCompany = user.userData.profile.companyNumber ? user.userData.profile.companyNumber : this.userCompany

                        let obj = {
                            firstName: [''],
                            lastName: [''],
                            email: [''],
                            username: [''],
                            corpId: [user.userData.profile.corpId],
                            customerNumber: [!this.showCustomer ?  this.userCustomer : ''],
                            companyNumber: [!this.showCompany ? this.userCompany  : ''],
                            deletedAt: [null],
                            primaryClientBranch_uuid: [(!foundFirm && !foundOffice) ? user.userData.profile.primaryClientBranch_uuid : '']
                        }
                        this.filterFormGroup = this.fb.group(obj)
                        this.filterSubmit();
                    }
                })
                
                if(!authenticated) return;
                this.auth.userData$.pipe(filter(ls => !!ls.userData.profile)).subscribe((user)=>{
                    if(user.userData.profile.corpId) {
                        this.userCorp = user.userData.profile.corpId
                        this.userBranch = user.userData.profile.primaryClientBranch_uuid
                        this.userCustomer = user.userData.profile.customerNumber
                        this.userCompany = user.userData.profile.companyNumber
                        let obj = {corpId: this.userCorp}
                        if(!this.showCompany){
                            Object.assign(obj, {companyNumber:this.userCompany})
                        } else if(!this.showCustomer){
                            Object.assign(obj, {customerNumber:this.userCustomer})
                        } 

                        this.filterFormGroup = this.fb.group(obj)
                        this.filterSubmit();
                    };
                })
            });
        })
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit()
        setTimeout(()=>{
            if(!this.filterFormGroup){
                this.auth.userData$.pipe(filter(ls => !!ls.userData.profile)).subscribe((user)=>{
                    let foundFirm = this.userPermissionService.loggedInUserRoles.find((role)=> role.name == 'Client Firm Administrator');
                    let foundOffice = this.userPermissionService.loggedInUserRoles.find((role)=> role.name == 'Client Office Administrator');
                    // let foundAccount = this.userPermissionService.loggedInUserRoles.find((role)=> role.name == 'Client Account Administrator');
                    
                    if(foundFirm){
                        this.showCompany = true;
                        this.showCustomer = true;
                    } else if(foundOffice){
                        this.showCustomer = true;
                    }

                    this.userCustomer = user.userData.profile.customerNumber
                    this.userCompany = user.userData.profile.companyNumber

                    this.filterFormGroup = this.fb.group({
                        firstName: [''],
                        lastName: [''],
                        email: [''],
                        username: [''],
                        corpId: [this.userCorp],
                        customerNumber: [!this.showCustomer ? this.userCustomer : ''],
                        companyNumber: [!this.showCompany ?  this.userCompany : ''],
                        deletedAt: [null],
                        primaryClientBranch_uuid: [!this.showCustomer ? this.userBranch : '']
                    });
                    this.filterSubmit();
                })
            }else{
                this.filterSubmit();
            }

            this.filterFormGroup!.valueChanges.subscribe((jsonObject)=>{
                this.userDevicePreferences.fcFilter = jsonObject;
            })

            if (this.dataSource.sort) {
                this.dataSource.sort.active = 'username';
                this.dataSource.sort.direction = 'desc';
            }

            this.auth.isAuthenticated$.subscribe((authenticated) => {
                if(!authenticated) return;
    
                let filterClick = document.getElementById('filterPaneUser');
                if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                    filterClick.setAttribute('filterClickAttached','true')
                    filterClick.addEventListener("click",()=>{
                        sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                    })
                }
            });
            
        })

        this.dataSource.loading.subscribe((state)=>{
            this.isLoading = state;
        })
    }

    filterSubmit(reset?: boolean){
        if(reset){
            this.filterFormGroup = this.fb.group({
                firstName: [''],
                lastName: [''],
                email: [''],
                username: [''],
                corpId: [this.userCorp],
                customerNumber: [!this.showCustomer ? this.userCustomer : ''],
                companyNumber: [!this.showCompany ? this.userCompany : ''],
                deletedAt: [null],
                primaryClientBranch_uuid: [!this.showCustomer ? this.userBranch : '']
            });
        }
    
        this.setBreadCrumbs();
        this.onFilterSubmit();
    }
}