import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export enum ClientBranchTerms {
	"Net 15",
	"Net 30",
	"Net 45",
	"Net 60",
	"Net 75",
	"Net 90",
	"Net 180",
	"Net 360",
	"Net 720"
}

export var ClientBranchTermsOptions = Object.keys(ClientBranchTerms).filter(k => isNaN(Number(k)));

/**
 * jbethke is uncertain what class is actually used for, but it seems to only apply to accounts sourced from FLED, below are the distinct values found in the data
 */
export enum ClientClass {
	DIS = "DIS",
	DISEDU = "DIS:EDS",
	DISTRL = "DIS:TRL"
}

export var ClientClassOptions = Object.values(ClientClass);

export class ClientBranch extends DexResourceModel {

	@Field(String)
    public clientId: string = "";

	@Field(String)
	public name: string = "";

	@Field(String)
	public default_clientMatterSet_uuid: string | null = null;

	@Field(String)
	public insuranceCompany: string | null = null;

	@Field(String)
	public insuranceAdjuster: string | null = null;

	// Deprecated as part of CMSI-294 in favor of `addressBook.clientBranch_uuid`
	// @Field(String)
	// public addressBook_uuid: string | null = null;

	@Field(Number)
	public customerNumber: number = 0;

    @Field(String)
    public errorMessage: string | null = null;

    @Field(String)
    public companyNumber: number = 0;

	@Field(Number)
	public masterCustomerNumber: number | null = null;

	@Field(Number)
	public masterCompanyNumber: number | null = null;

	@Field(String)
	public terms: ClientBranchTerms | string | null = null;

	@Field(String)
	public class: string | null = null;

	@Field(String)
	public territory_uuid: string | null = null;

	@Field(String)
	public region_uuid: string | null = null;

    @Field(String)
    public accountType: string = "Order Entry";

    @Field(String)
    public accountStatus: string = "Inactive";

    @Field(String)
    public parentBillingAccountBranch_uuid: string | null = null;
    
	@Field(String)
	public serviceLine_uuid: string | null = null;

	@Field(String)
	public customerType: string = "Standard";

    @Field(String)
    public companyBranch_uuid: string | null = null;

    @Field(String)
    public officeCode: string | null = null;

    @Field(String)
    public syncLocks: string | null = null;

    @Field(String)
    public netsuiteId: string | null = null;

	constructor(data?: any) {
		super();
		this.mapData(data);
	}
}
