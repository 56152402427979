import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { SequenceOrderType } from '../models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class SequenceOrderType_Service extends ResourceService<SequenceOrderType> {
    protected servicePath: string = '/SequenceOrderType';

    public ModelType = SequenceOrderType;
    
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    public getSequenceOrderTypeInfo(name:string): Observable<any> {
        return this.http
        .post(
            this.serviceUrl + 'getSequenceOrderTypeInfo',
            {
                name:name
            },
            {
                observe: 'response'
            }
        )
        .pipe(
            map(
                (response: HttpResponse<any>) => {
                    return response.body;
                }
            )
        );
    }
}