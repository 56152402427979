import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { FieldLockService } from "../../services";

@Component({
    selector: 'field-lock',
    templateUrl: 'field-lock.component.html',
    styleUrls: ['field-lock.component.scss']
})
export class FieldLockComponent implements OnInit, OnDestroy{
    @Input()
    public fieldName!: string;

    @Input()
    public fieldLockService!: FieldLockService;

    @Input()
    public valueChanges!: Observable<string>;
    
    public sub: Subscription | undefined;

    public unlockedToolTip = 'This field is unlocked and can be overwritten by a source system of record.';
    public lockedToolTip = 'This field is locked and can not be overwritten by a source system of record.';

    public ngOnInit(): void {
        this.sub = this.valueChanges?.pipe(take(1)).subscribe((_) => {
            if (!this.fieldLockService?.isLocked(this.fieldName)) {
                this.fieldLockService.lockField(this.fieldName);
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.sub) {
            this.sub?.unsubscribe();
        }
    }

    public toggleLockedStatus() {
        if (this.fieldLockService?.isLocked(this.fieldName)) {
            this.fieldLockService.unlockField(this.fieldName);
        } else {
            this.fieldLockService?.lockField(this.fieldName);
        }
    }

}