import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ServeManagerProcessingOrders extends DexResourceModel {

    @Field(Number)
    public savedOrdersId: number | null = null;

    @Field(Number)
    public smOrderId: number | null = null;

    @Field(String)
    public orderData: string | null = null;

    @Field(Date)
    public processingStart: Date | null = null;

	public constructor(jsonData?: { [key: string]: any; }) {
		// Constructor calls empty super
		super();

		// Map the data ourselves using the inherited function. If the parent
		// constructor maps it, typescript will overwrite our data with our
		// defaults afterwards.
		if (jsonData) {
			this.mapData(jsonData);
		}
	}
}