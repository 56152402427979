import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SuccessCountry } from "../models/country.model";
import { HttpClient, HttpResponse } from '@angular/common/http'
import { map } from 'rxjs/operators';


@Injectable()
export class SuccessCountry_Service extends ResourceService<SuccessCountry>{
    protected servicePath: string = '/Country';
    public ModelType = SuccessCountry;

    public getAllCountries(){
        return this
            .http
            .post(
            this.serviceUrl + 'getAllCountries',
            {
                observe: 'response'
            }
        )
    }

    constructor( protected http: HttpClient ){
        super(http);
    }
}