import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
    SuccessAccountRoleName,
    SuccessCase,
    SuccessCase_Service,
    UserPermission_Service,
    WIP,
    SuccessContactWithType,
    Contact,
    AddressBookService,
    AddressBook,
    SuccessAccountFirm_Service,
    NotificationType_Service,
    Notification_Service,
    PagedResourceListComponent,
    SuccessCaseDataSource,
    SuccessContact,
    SuccessCaseContact,
    SuccessContact_Service,
    SuccessCaseContact_Service,
    SuccessAccountPermissionNodeName,
    SystemConfig_service,
    UserDevicePreferencesService
} from 'legalreflex-lib';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CaseRestoreDialog } from '../restore/caseRestoreDialog';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { ParticipantEditPopupDialog } from '../participantEdit/participantEdit';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceListPane } from '@smartsoftware/reflex-core';

@Component({ 
    templateUrl: './list.html',
    styleUrls: ['./list.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class CaseList extends PagedResourceListComponent<SuccessCase> {

    @ViewChild(MatSort) sort: MatSort = new MatSort();
    @ViewChild('contactSort') contactSort: MatSort = new MatSort();
    @ViewChild('filter') filterPane: ResourceListPane = new ResourceListPane();
    public filterCollapsed: boolean = sessionStorage.getItem("filterCollapsed") == 'true';

    public WIP : boolean = WIP()
    public userEmail: string = ''
    public userId: string = ''
    public isAdmin: boolean = false
    public isFirstLegal: boolean = false
    public activeContacts: Array<Contact[]> = [[],[],[]]
    public contactSource: MatTableDataSource<SuccessContactWithType> = new MatTableDataSource<SuccessContactWithType>()
    public contactEdit: SuccessContactWithType | null = null
    public isLoading: boolean =  true
    public courtAddress: AddressBook | null = null
    public expandedEntity: SuccessCase | null = null
    public noCaseFound: boolean = false
    public corpUsers: Map<string, string> = new Map<string, string>()
    public participantColumns = ['view', 'contactType', 'firstName', 'email', 'phone', 'companyName', 'address', 'city', 'state']
    public caseColumns = ['select', 'view', 'name', 'caseNumber', 'clientMatterNumber', 'successAccount_uuid']
    constructor(
        public dataSource: SuccessCaseDataSource,
        public resourceService: SuccessCase_Service,
        public router: Router,
        public fb: FormBuilder,
        protected auth: OidcSecurityService,
        protected contactService: SuccessContact_Service,
        protected caseContactService: SuccessCaseContact_Service,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        protected successAccountService: SuccessAccountFirm_Service,
        protected addressBookService: AddressBookService,
        protected notificationTypeService: NotificationType_Service,
        protected notificationService: Notification_Service,
        private userPermissionService: UserPermission_Service,
        private breadCrumbService: BreadCrumbService,
        public sysConfig: SystemConfig_service,
        public userDevicePreferences: UserDevicePreferencesService
    ) { 
        super(dataSource, resourceService, dialog, route, router)
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Case Manager', url:'/case'},
            { label: 'History'}
        ])
        this.setBreadCrumbs()

        this.filterFormGroup = this.fb.group({
            name: [''],
            caseNumber: [''],
            clientMatterNumber: [''],
            user: [''],
            isRestricted: ['either'],
            deletedAt: ['notDeleted'],
            source: ['success'],
        })
        this.filterFormGroup.patchValue(this.userDevicePreferences.fcFilter)
        Object.keys(this.dataSource.filters).map((key)=>{this.dataSource.filters[key] = this.userDevicePreferences.fcFilter[key]})
    }


    ngOnInit() {
        super.ngOnInit();
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        this.auth.userData$.subscribe(({userData, allUserData}) => {
			let response = userData;
            this.userId = response.profile.uuid
            this.userEmail = response.profile.email
            this.userPermissionService.userSync.subscribe((res)=>{
                if(this.userPermissionService.hasRole(SuccessAccountPermissionNodeName.manage_all_cases_in_system)){
                    this.isAdmin = true
                    this.isFirstLegal = true
                }else if(this.userPermissionService.hasRole(SuccessAccountPermissionNodeName.manage_firm_cases)){
                    this.isAdmin = true
                }
            })
        })
        this.successAccountService.list().subscribe((res)=>{
            res.map((user)=>{
                this.corpUsers.set(user.id.toString(), user.firstName + ' ' + user.lastName)
            })
        })
        this.dataSource.sort = this.sort
        this.dataSource.pageSize = 25

    }

    ngAfterViewInit() {
        super.ngAfterViewInit()
        setTimeout(() => {
            this.filterFormGroup!.valueChanges.subscribe((jsonObject)=>{
                this.userDevicePreferences.fcFilter = jsonObject;
            })
            if (this.dataSource.sort) {
                this.dataSource.sort.active = 'name'
                this.dataSource.sort.direction = 'asc'
            }
            this.getCases()
            let filterClick = document.getElementById('filterPaneCase');
            if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                filterClick.setAttribute('filterClickAttached','true')
                filterClick.addEventListener("click",()=>{
                    sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                })
            }
        })
        this.dataSource.loading.subscribe((state)=>{
            this.isLoading = state
        })
    }

    sortDatasource(event: any){
        if(event == 'case'){
            this.selection.clear()
            this.setBreadCrumbs()
        }
        else
            this.contactSource.data = this.contactSource.sortData(this.contactSource.data,this.contactSort)
    }

    selectionChange(event?:any){
        if(event){
            event.stopPropagation()
        }
        // To fire after selection update goes through
        setTimeout(() => {
            this.setBreadCrumbs()   
        })
        
    }

    pageChange(event:any){
        this.selection.clear()
        this.setBreadCrumbs()
    }

    setBreadCrumbs(){

        let actions = []
        actions.push({label: 'Refresh', action:this.getCases.bind(this,true), icon: 'refresh'})
        if(!this.selection.isEmpty()){
            actions.push({label: 'Delete Case(s)', action:this.deleteEntries.bind(this), icon: 'delete'})
        }else{
            actions.push({label: 'Delete Case(s)', disabled:true, icon: 'delete', tooltip:"Please select 1 or more cases."})
        }
        actions.push({label: 'New Case', routerLink: "/case/new", isPrimary: true, icon: 'library_add'})
        this.breadCrumbService.actions.next(actions)
    }

    getCases(reset?: boolean){
        if(reset){
            this.filterFormGroup = this.fb.group({
                name: [''],
                caseNumber: [''],
                clientMatterNumber: [''],
                user: [''],
                isRestricted: ['either'],
                deletedAt: ['notDeleted'],
                source: ['success'],
            })
        }


        if(this.filterFormGroup?.controls['deletedAt'].value)
            this.filterFormGroup?.controls['deletedAt'].setValue(this.filterFormGroup?.controls['deletedAt'].value)
        else if(this.filterFormGroup?.controls['deletedAt'])
            delete this.filterFormGroup?.controls['deletedAt']

        this.onFilterSubmit()
        this.selection.clear()
    }

    getEntity(entity:SuccessCase){
        this.expandedEntity = this.expandedEntity === entity ? null : entity
        if(this.expandedEntity == null)
            return
        this.courtAddress = null
        this.contactSource.data = []
        this.resourceService.getCaseContacts(entity.uuid).subscribe((entity)=>{
            this.contactSource.data = this.contactSource._filterData(entity.slice(0,11))
        })    
        if(entity.court_AddressBook_uuid)
            this.addressBookService.get(entity.court_AddressBook_uuid).subscribe((res)=>{
                this.courtAddress = res
            })
    }

    deleteParticipant(entity:SuccessContactWithType){
        this.caseContactService.search({where:{filters:{uuid:entity.caseContact_uuid}}}).subscribe((res)=>{
            let entry = res.results[0]
            entry.isDeleted = true
            entry.deletedAt = new Date()
            this.caseContactService.push(entry).subscribe((deleted)=>{
                let data = this.contactSource.data.filter((contact)=>{return contact.uuid != entry.contact_uuid})
                this.contactSource.data = this.contactSource._filterData(data)
            })
        })
    }

    editParticipant(participant?:SuccessContactWithType){
        let expanded = this.expandedEntity?.uuid
        if(!expanded)
            return
        let entity : SuccessContactWithType = {uuid:"", }
        if(participant)
            entity = participant
        this.dialog.open(ParticipantEditPopupDialog, {
            data: entity
        }).afterClosed().subscribe((saved: SuccessContactWithType | undefined)=>{
            if(saved && saved.isOrganization == true){
                saved.firstName = undefined
                saved.lastName = undefined
            }
            if(saved){
                if(!saved.uuid){
                    let contact = new SuccessContact()
                    let caseContact = new SuccessCaseContact()
                    Object.assign(contact, saved)
                    this.contactService.push(contact).subscribe((newContact:SuccessContact)=> {
                        Object.assign(caseContact, {'case_uuid':expanded, 'contact_uuid':newContact.id.toString(), 'contactType':saved.contactType })
                        this.caseContactService.push(caseContact).subscribe((res)=>{
                            saved.caseContact_uuid = res.id.toString()
                            this.contactSource.data.push(saved)
                            this.contactSource.data = this.contactSource.sortData(this.contactSource.data, this.contactSort)
                        })        
                    })
                }else if(saved.uuid){
                    let contact = new SuccessContact()
                    Object.assign(contact, saved)
                    this.contactService.push(contact).subscribe((editedContact:SuccessContact)=> {
                        let dataInd = this.contactSource.data.findIndex((participant)=> participant.uuid == saved.uuid)
                        this.contactSource.data[dataInd] = saved
                        this.contactSource.data = this.contactSource.sortData(this.contactSource.data, this.contactSort)
                    })
                }
            }
        })
    }

    deleteEntries(){
        this.openConfirmDeleteDialog().subscribe(response => {
            if (response.confirmed) {
                let observables = this.selection.selected.map((selectedCase)=>{
                    selectedCase.deletedAt = new Date()
                    selectedCase.isDeleted = true
                    return this.resourceService.updateCase((selectedCase))
                })
                this.isLoading = true
                combineLatest(observables).pipe(
                    tap(_ => {
                        this.selection.clear()
                        this.setBreadCrumbs()
                        this.getCases()
                    })
                    )
                    .subscribe({
                        error: error => this.openErrorDialog(error)
                    })
            }
        })

    }

    restoreEntry(entity: any){
        this.dialog.open(CaseRestoreDialog, {
            data: entity
        }).afterClosed().subscribe((res)=>{
            if(res){
                entity.deletedAt = null
                entity.isDeleted = false
                this.resourceService.updateCase(entity).subscribe(() => {});
            }
        })
    }
}
