


export class PDFObject
{
    
    public title:       string | null = null;
    public plaintiff:   string | null = null;
    public defendant:   string | null = null;
    public caseName:    string | null = null;
    public caseNo:      string | null = null;
    public pageCount:   number | null = null;
    public thumbnails:  (Blob | null)[] = [null, null, null];
    public document:    File;
    public processed:   boolean = false;
    public corrupted:   boolean = false;
    public rendered:    boolean = false;
    public removed:     boolean = false;
    public addDate:     Date = new Date();
    
    constructor(doc: File) { this.document = doc; }
    
}