import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ReflexEnvironment as environment } from '@smartsoftware/reflex-core';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import {
    Role, 
    Role_Service,
    SuccessAccount,
    SuccessAccount_Service,
    UserPermission_Service,
    VendorAccount,
    VendorAccount_Service,
    Vendor,
    Vendor_Service,
    Order,
    OrderStatus,
    Order_Service,
    OrderHistoryUserFilter,
    PermissionNode,
    PermissionNode_Service,
    RolePermissionNode,
    RolePermissionNode_Service,
    SuccessAccountRole,
    SuccessAccountRoleName,
    SuccessAccountRole_Service,
    SuccessAccountPermissionNode,
    SuccessAccountPermissionNode_Service,
    ApiHealth_Service,
    Notification as Notice,
    Notification_Service,
    Mailing_Service,
    SystemConfig_service,
    SystemConfig,
    SuccessAccountSettings,
    SuccessAccountSettingsService,
    ContactPreference,
    ContactTrigger,
    ContactFrequency,
    PageParams,
    Dictionary_Service
} from 'legalreflex-lib';

import { User } from '@auth0/auth0-angular';
import { combineLatest } from 'rxjs';
import { RequestAcclaimAccountDialog } from '../../../components/accountRequest/requestAcclaimAccount';
import { FirstLoginDialog } from './dialog/firstLogin';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationPopupDialog } from '../../notification/dialog/notificationPopup';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';

@Component({
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.scss']
})
export class DashboardView {

    private _authenticated: boolean;
    private _logoffRequired: boolean;
    private _authAvailable: boolean;
    private _vendors: Array<Vendor>;
    private _vendorAccountData: Array<any>;
    // public _showAvailableFirstConnectAccounts: boolean;
    // public _showAvailableAcclaimAccounts: boolean;
    // public _showAvailableTristarAccounts: boolean;
    // public _disableFirstConnect: boolean;
    public _disableAcclaim: boolean;
    public _disableTristar: boolean;

    // public signupFirstConnect: string;
    public signupAcclaim: string;
    public signupTristar: string;

    public FirstConnectVendorAccounts: Array<VendorAccountData>;
    public AcclaimVendorAccounts: Array<VendorAccountData>;
    public TristarVendorAccounts: Array<VendorAccountData>;
    public notifications : BehaviorSubject<Notice[]> = new BehaviorSubject<Notice[]>([]);

    public orders : BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
    public drafts : BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
    public loadingOrders : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public orderTableColumns = ['action', 'orderNumber', 'orderDate', 'lastUpdated', 'displayStatus'];
    public draftTableColumns = ['detailAction', 'resumeAction', 'orderNumber', 'createdAt', 'orderStatus'];

    public maintenanceStatus: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    public maintenanceMessage: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    public authConfigId: string|undefined; 

    constructor(
        public auth: OidcSecurityService,
        protected dialog: MatDialog,
        private RoleService: Role_Service,
        private RolePermissionNodeService: RolePermissionNode_Service,
        private SuccessAccountService: SuccessAccount_Service,
        private UserPermissionService: UserPermission_Service,
        private PermissionNodeService: PermissionNode_Service,
        private VendorAccountService: VendorAccount_Service,
        private SuccessAccountSettingsService: SuccessAccountSettingsService,
        private VendorService: Vendor_Service,
        private SuccessAccountRoleService: SuccessAccountRole_Service,
        private SuccessAccountPermissionNodeService: SuccessAccountPermissionNode_Service,
        private NotificationService: Notification_Service,
		private breadCrumbService: BreadCrumbService,
        protected orderService: Order_Service,
        public ApiHealthService: ApiHealth_Service,
        public mailSystem: Mailing_Service,
        public SystemConfigService: SystemConfig_service,
        public dictionary: Dictionary_Service,
    ) {
        this._authenticated = false;
        this._logoffRequired = false;
        this._authAvailable = true;
        // this._showAvailableFirstConnectAccounts = false;
        // this._showAvailableAcclaimAccounts = false;
        // this._showAvailableTristarAccounts = false;
        // this._disableFirstConnect = true;
        this._disableAcclaim = true;
        this._disableTristar = true;
        this.breadCrumbService.actions.next([]);
        // this.signupFirstConnect = environment.config['FirstConnectSignupUrl'];
        this.signupAcclaim = environment.config['AcclaimSignupUrl'];
        this.signupTristar = environment.config['TristarSignupUrl'];
        this._vendors = [];
        this._vendorAccountData = [];

        this.FirstConnectVendorAccounts = [];
        this.AcclaimVendorAccounts = [];
        this.TristarVendorAccounts = [];
    }

    get authAvailable() {
        return this._authAvailable;
    }

    set authAvailable(status: boolean) {
        this._authAvailable = status;
    }

    get maintenanceMode()
    {
        // console.log("maintenanceModeCalled:\n", this.maintenanceStatus.value);
        if(this.maintenanceStatus.value == true)
            return true;
        return false;
    }

    get maintenanceModeMessage()
    {
        // console.log("maintenanceModeMessageCalled:\n", this.maintenanceMessage.value);
        return this.maintenanceMessage.value;
    }

    public get now() : Date {
        return new Date();
    }

    login() {
        this.auth.authorize();
    }

    private buildFirstConnectAccount(firstConnectUsername: string, successId: string, email: string) : VendorAccount | null {
        // first connect
        let firstConnectVendor = this._vendors
            .find((vendor: Vendor) => {return vendor.name == "FirstConnect"});

        // guard if FirstConnect vendor not found 
        if(firstConnectVendor == undefined) {
            console.log('ERROR: Could not find vendor: FirstConnect');
            
            // logoff here because because Success needs a VendorAccount
            this._logoffRequired = true;
            this.auth.logoff();

            return null;
        }

        // TFADD update this to include the customer#, company#, and corpID
        let vendorAccount = new VendorAccount({
            vendor_uuid: firstConnectVendor.uuid,
            successAccount_uuid: successId,
            username: firstConnectUsername,
            vendorName: "FirstConnect",
            email: email
        });

        return vendorAccount;
    }

    private buildNewVendorAccounts(firstConnectUsername: string, successId: string, firstTime: boolean, email: string) : Array<VendorAccount> {
        let newVendorAccounts: Array<VendorAccount> = []; 
        
        if(firstTime) {
            let vendorAccount = this.buildFirstConnectAccount(firstConnectUsername, successId, email);
            if(vendorAccount != null)
                newVendorAccounts.push(vendorAccount); 
        }

        // handle other vendor account data (tristar, acclaim)
        this._vendorAccountData.map((accountData: any) => {
            
            let username: string | null;

            // set username based on vendor
            // TFADD also needs to store for acclaim (accountName, accountNumber), still waiting on what data to store for tristar
            switch(accountData.vendor) {
                case 'Acclaim': {
                    username = accountData.loginName;
                    break;
                }
                case 'Tristar':
                default: 
                    username = null; // accountData.Contact
            }

            let vendor = this._vendors
                .find((vendor: Vendor) => { return vendor.name == accountData.vendor; });
            
            // guard if vendor not found
            if(vendor == undefined) {
                console.log('ERROR: Could not find vendor:', accountData.vendor);
                // might need to handle this with an email report
                return;
            }
            
            // TFADD update this to include the customer#, company#, and corpID (might not be needed)
            let vendorAccount = new VendorAccount({
                vendor_uuid: vendor.uuid,
                successAccount_uuid: successId,
                username: username,
                vendorName: vendor.name,
                email: email
            });

            newVendorAccounts.push(vendorAccount);
        });

        return newVendorAccounts;
    }

    public checkDraftOwner(entity: Order) : boolean {
        return (entity.successAccount_uuid == this.UserPermissionService.loggedInUser?.uuid);
    } 

    private clearVendorAccounts() {
        this.FirstConnectVendorAccounts = [];
        this.TristarVendorAccounts = [];
        this.AcclaimVendorAccounts = [];
    }

    private processVendorAccounts(accounts: Array<VendorAccount>): void {
        this.clearVendorAccounts();
        accounts.map((account) => {
            let accountData: VendorAccountData = {
                vendorAccountId: account.uuid,
                vendorName: account.vendorName,
                username: account.username
            }

            if(account.vendorName == "FirstConnect") {
                this.FirstConnectVendorAccounts.push(accountData);
                // this._disableFirstConnect = false;
            } else if (account.vendorName == "Acclaim") {
                this.AcclaimVendorAccounts.push(accountData);
                this._disableAcclaim = false;
            } else if (account.vendorName == "Tristar") {
                this.TristarVendorAccounts.push(accountData);
                this._disableTristar = false;
            }
        });
    }

    ngOnInit() {
        this.SystemConfigService.getSysConfigListObservable().subscribe((mode: any) =>
        {
            // console.log("SysConfigList:\n", mode);
            this.maintenanceStatus.next(this.SystemConfigService.getMaintenanceMode('isInMaintenanceMode'));
            this.maintenanceMessage.next(this.SystemConfigService.getMaintenanceMode('maintenanceModeMessage'));
            // this.maintenanceMessage.next(this.SystemConfigService.getMaintenanceMode('test3'));
            // console.log("maintenanceStatus:\n", this.maintenanceStatus.value);
            // console.log("maintenanceMessage:\n", this.maintenanceMessage.value);
        });

        this.ApiHealthService.authAvailable.subscribe((status: boolean) => {
            this.authAvailable = status;
        })

        this.auth.isAuthenticated$.subscribe((authenticated) => {
            this._authenticated = authenticated.isAuthenticated;
            
            // gaurd to prevent multiple calls to logic.
            if(!this._authenticated) return;
            
            this.authConfigId = this.auth.getConfiguration().configId;

            this.NotificationService.getRecentNotificationsIgnorePush({createdAt: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7 * 52)), includeViewed:true, notificationLimit:5}).subscribe((res)=>{
                // If we don't have any notifications, seed it with a stub one
                if(!res.notifications.length) 
                    res.notifications.push(new Notice({subject:"Welcome to FirstConnect", contentHtml: `<p>Congratulations.</p><p>You've joined First Connect and received your first notification.</p>`, uuid:"welcome", viewed:false, createdAt: null}))
                
                this.notifications.next(res.notifications);
            });

            this.UserPermissionService.userSync.subscribe(
                (userSynced: boolean) => {
                    if(!userSynced) return;
                    
                    this.fetchOrders();
            });

            // DEV NOTE: this must be the last thing called or ngOnInit will return before it completes 
            // and account upgrade won't work
            this.logic();
        });
    }

    public openNotification(entity:Notice){
        window.focus()
        let dialogRef: MatDialogRef<NotificationPopupDialog>;
        dialogRef = this.dialog.open(NotificationPopupDialog, {
            data: entity
        })
        dialogRef.afterClosed().subscribe((res)=>{
            if(res){
                Object.assign(entity, res.data)
                this.NotificationService.push(entity).subscribe((ents)=>{
                    console.log(ents)
                })
            }
        })
    }

    public logic() {
        this.auth.getUserData();
        this.auth.userData$.subscribe(({userData, allUserData}) => {
            // for some unknown reason userData might be null
            // guard when user is not logged in, or was just logged out
            if (!this._authenticated || this._logoffRequired || !userData || !userData.sub) return;
            else {
                if(userData?.profile && userData.profile.source == "Tristar") {
                    window.location.href = environment.config['TristarLoginUrl'];
                    return;
                }
                    // console.log('authed', this._authenticated);
                // get vendor account data for creating new vendor accounts
                this.VendorAccountService.getOtherVendorAccounts({email: userData.email});
                this.RoleService.syncRoleData();

                this.NotificationService.getRecentNotificationsIgnorePush({createdAt: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7 * 52)), includeViewed:true, notificationLimit:5}).subscribe((res)=>{
                    // If we don't have any notifications, seed it with a stub one
                    if(!res.notifications.length) 
                        res.notifications.push(new Notice({subject:"Welcome to FirstConnect", contentHtml: `<p>Congratulations.</p><p>You've joined First Connect and received your first notificaiton.</p>`, uuid:"welcome", viewed:false, createdAt: null}))
                    
                    this.notifications.next(res.notifications);
                });
                
                // get vendors and default roles
                combineLatest([
                    this.VendorService.dataSync,
                    this.RoleService.dataSync,
                    this.UserPermissionService.userSync
                ])
                .subscribe((response:Array<boolean>) => {
                    // need to wait on vendors
                    if(response.includes(false)) return; 
                    else {
                    this._vendors = this.VendorService.vendors;

                    // grab existing vendor accounts
                    this.VendorAccountService.getVendorAccountsByUsernameAndVendor({ username: userData.sub }).subscribe(
                        (vendorAccountResponse: Array<VendorAccount>) => {
                            this.processVendorAccounts(vendorAccountResponse);
                            
                            // user logged in on an existing success account no need to create a new account
                            if (userData?.profile && userData.profile.source == "SuccessConnect") {
                                if(this.UserPermissionService.loggedInUser) {
                                    let lastLogin = this.UserPermissionService.loggedInUser.lastLogin;
                                    let issueAt = new Date(JSON.parse(atob(this.auth.getIdToken().split('.')[1])).iat * 1000);
                                    let needToUpdateLastLogin = false;
                                    //if lastLogin is newer than issue at time, then do not update last login
                                    if(lastLogin) needToUpdateLastLogin = lastLogin?.getTime() > issueAt?.getTime()? false:true;
                                    if(lastLogin == null || needToUpdateLastLogin) {
                                        this.UserPermissionService.loggedInUser.lastLogin = issueAt;
                                        let request: {password?: string, lastLogin:Date, successAccount_uuid: string} 
                                            = {lastLogin:issueAt, successAccount_uuid: this.UserPermissionService.loggedInUser.uuid}
                                        //if it is user's first time logging in, open the new password dialog to make them reset
                                        if(lastLogin == null) {
                                            let dialogRef: MatDialogRef<FirstLoginDialog>;
                                            dialogRef = this.dialog.open(FirstLoginDialog, {
                                                disableClose: true,
                                                data: {
                                                    username: userData.sub,
                                                    password: ""
                                                }
                                            });

                                            // handle the new password response
                                            dialogRef.afterClosed().subscribe((result: any) => {
                                                request.password = result.password;
                                                this.SuccessAccountService.updateLastLogin(request).subscribe(()=>{});
                                            });
                                        } else {
                                            this.SuccessAccountService.updateLastLogin(request).subscribe(()=>{});
                                        }
                                    }
                                }
                                
                                // get other vendor account data from external sources, so if more accounts are added 
                                // after the initial account upgrade we can still grant SSO access to those portals
                                this.VendorAccountService.otherVendorData.subscribe((newVendorData) => {
                                    // match vendor account data with our list of vendor accounts for the user,
                                    // filtering out those that already exist.
                                    let vendorUpdates = newVendorData.filter((vendorData) => {
                                        if(vendorData.vendor == "Tristar" && this.TristarVendorAccounts.length == 0) {
                                            return true; 
                                        }

                                        else if(vendorData.vendor == "Acclaim" && this.AcclaimVendorAccounts.length == 0) {
                                            return true;
                                        }

                                        return false;
                                    });

                                    // No new vendors to add
                                    if(vendorUpdates.length == 0) {
                                        // let them in as normal
                                        return;
                                    }

                                    // update the vendor accounts, this is used in the "buildNewVendorAccounts"
                                    this._vendorAccountData = vendorUpdates;

                                    // first argument can be empty string here because we aren't creating a new 
                                    // FirstConnect VendorAccount, that only happens on the initial account upgrade
                                    let newVendors = this.buildNewVendorAccounts("", this.UserPermissionService.loggedInUser!.uuid, false, userData.email);
                                    
                                    // insert any new vendors to the VendorAccount table
                                    this.VendorAccountService.createVendorAccounts(newVendors).subscribe((updatedVendorAccounts) => {
                                        // console.log('TEST updated vendor accounts', updatedVendorAccounts);

                                        // grab the updated list of VendorAccount from the Success database
                                        this.VendorAccountService.getVendorAccountsByUsernameAndVendor({ 
                                            username: this.UserPermissionService.loggedInUser!.username 
                                        }).subscribe((vendorAccountResponse: Array<VendorAccount>) => {
                                            // so we can repopulate with new ones
                                            this.processVendorAccounts(vendorAccountResponse);

                                            // let them in as normal
                                            return;
                                        },
                                        (error: any) => {
                                            console.error('ERROR: could not get vendor account info', error);

                                            return;
                                        })
                                    },
                                    (error: any) => {
                                        console.error('ERROR: could not update vendor account', error);

                                        return;    
                                    })
                                },
                                (error: any) => {
                                    console.error('ERROR: Could not get vendor account data', error);

                                    this._logoffRequired = true;
                                    this.auth.logoff();

                                    return;
                                });
                            } else {
                                console.log('ERROR: profile missing data', userData);

                                this._logoffRequired = true
                                this.auth.logoff();
                            }
                        },
                        (error: any) => {
                            console.log('ERROR: Could not retrieve VendorAccount data', error);

                            this._logoffRequired = true;
                            this.auth.logoff();
                        }
                    );
                    }
                });
            };
        });
    }

    protected fetchOrders() {

        if(!this.UserPermissionService.loggedInUser) return;
        this.loadingOrders.next(true);

        let twoWeeksPrior = new Date();
        twoWeeksPrior.setDate(twoWeeksPrior.getDate() - 14)
        twoWeeksPrior.setHours(0,0,0,0);

        let draftFilters : OrderHistoryUserFilter = {
            byUser: true,
            orderStatus: [OrderStatus.DRAFT],
            placedByEmail: this.UserPermissionService.loggedInUser?.email,
            updatedAt: [twoWeeksPrior]
        };
        
        let orderFilters : OrderHistoryUserFilter = {
            byUser: true,
            orderStatus: [OrderStatus.CANCELED, OrderStatus.DRAFT],
            negateOrderStatusMatch: true,
            placedByEmail: this.UserPermissionService.loggedInUser?.email,
            updatedAt: [twoWeeksPrior]
        };

        let draftPageParams : PageParams = {
            page: 1,
            pageSize: 50,
            sortOrder: 'desc',
            sortColumn: 'updatedAt',
            filters: draftFilters
        }

        let orderPageParams : PageParams = {
            page: 1,
            pageSize: 50,
            sortOrder: 'desc',
            sortColumn: 'updatedAt',
            filters: orderFilters
        }

        if(this.UserPermissionService.loggedInUser.username && this.UserPermissionService.loggedInUser.email)
        // TODO: This endpoint returns all the right data, but returns too much. Need a way to pass filters to only pull exactly what we need, or at least only pull last 2 weeks of data.
        combineLatest(
            this.orderService.historyList(orderFilters, orderPageParams),
            this.orderService.historyList(draftFilters, draftPageParams)
        ).subscribe(
            (entities : Order[][]) => {
                let sortedOrders = entities[0]
                    .sort((a,b) => (new Date(b.updatedAt!)).getTime() - (new Date(a.updatedAt!)).getTime());
                let newOrders = sortedOrders.slice(0, 10);
                let sortedDrafts = entities[1]
                    .sort((a,b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime());
                let newDrafts = sortedDrafts.slice(0, 5);
                this.orders.next(newOrders);
                this.drafts.next(newDrafts);
                this.loadingOrders.next(false);
            });
        else {
            this.orders.next([]);
            this.drafts.next([]);
            this.loadingOrders.next(false);
        }
    }
}

interface VendorAccountData {
    vendorName: string,
    username: string | null,
    vendorAccountId: string
}

export interface UserData {
    sub: string,
    email: string,
    profile: any
}