import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { CanLeaveService } from './canLeave.service';
import { ClientCode_Service } from './clientCode.service';
import { Project_BillingRateItem_Service } from './project_billingRateItem.service';
import { Project_ConflictParty_Service } from './project_conflictParty.service';
import { ProjectBillingRate_Template_Service } from './projectBillingRate_template.service';
import { ProjectBillingRate_TemplateItem_Service } from './projectBillingRate_templateItem.service';
import { ProjectType_Service } from './projectType.service';
import { ProjectStatus_Service } from './projectStatus.service';
import { User_Service } from './user.service';
import { ClientType_Service } from './clientType.service';
import { InvoiceTerm_Service } from './invoiceTerm.service';
import { ClientAddress_Service } from './clientAddress.service';
import { Case_Service } from './case.service';
import { RateTemplate_Service } from './rateTemplate.service';
import { Contact_Service } from './contact.service';
import { ContactType_Service } from './contactType.service';
import { Project_Service } from './project.service';
import { FileType_Service } from './fileType.service';
import { CaseFile_Service } from './caseFile.service';
import { ContactFile_Service } from './contactFile.service';
import { ClientFile_Service } from './clientFile.service';
import { ProjectFile_Service } from './projectFile.service';
import { FileAction_Service } from './fileAction.service';
import { PartnerVendor_Service } from './partnerVendor.service';
import { Note_Service } from './note.service';
import { CaseNote_Service } from './caseNote.service';
import { ContactNote_Service } from './contactNote.service';
import { ClientNote_Service } from './clientNote.service';
import { ProjectNote_Service } from './projectNote.service';
import { UserCase_Service } from './userCase.service';
import { UserProject_Service } from './userProject.service';
import { CaseContact_Service } from './caseContact.service';
import { ProjectContact_Service } from './projectContact.service';
import { PartnerVendorClient_Service } from './partnerVendorClient.service';
import { UserIdentity_Service } from './userIdentity.service';
import { File_Service } from './file.service';
import { PartnerVendorNotes_Service } from './partnerVendorNotes.service';
import { VendorType_Service } from './vendorType.service';
import { Billing_Service } from './billing.service';
import { ModelLibrary_Service } from './modelLibrary.service';
import { Order_Service, AdminOrder_Service } from './order.service';
import { Invoice_Service, AdminInvoice_Service } from './invoice.service';
import { OrderDetail_Service } from './orderDetail.service';
import { PermissionNode_Service } from './permissionNode.service';
import { Role_Service } from './role.service';
import { RolePermissionNode_Service } from './rolePermissionNode.service';
import { SuccessAccount_Service, SuccessAccountFirm_Service } from './successAccount.service';
import { SystemConfig_service } from './systemConfig.service';
import { SuccessAccountPermissionNode_Service } from './successAccountPermissionNode.service';
import { SuccessAccountRole_Service } from './successAccountRole.service';
import { UserPermission_Service } from './userPermission.service';
import { VendorAccount_Service } from './vendorAccount.service';
import { Vendor_Service } from './vendor.service';
import { ApiHealth_Service } from './apiHealth.service';
import { CommonData_Service } from './common-data.service';
import { AddressBookService } from './addressBook.service';
import { ImpersonateService } from './impersonate.service';
import { SuccessAccountSettingsService } from './success-account-settings.service';
import { OrderCache_Service } from './orderCache.service';
import { Mailing_Service } from './mailing.service';
import { SuccessCase_Service } from './successCase.service';
import { SuccessCaseContact_Service } from './successCaseContact.service';
import { SuccessContact_Service } from './successContact.service';
import { SuccessCaseAccess_Service } from './successCaseAccess.service';
import { Notification_Service } from './notification.service';
import { NotificationType_Service } from './notificationType.service';
import { FLCompany_Service } from './flCompany.service';
import { Client_Service } from './client.service';
import { FLCompanyBranch_Service } from './flCompanyBranch.service';
import { ClientPartnerVendor_Service } from './clientPartnerVendor.service';
import { ClientPartner_Service } from './clientPartner.service';
import { OrderContact_Service } from './orderContact.service';
import { ClientUser_Service }   from './clientUser.service'
import { ClientMatterEntry_Service } from './clientMatterEntry.service';
import { ClientMatterFtpLog_Service } from './clientMatterFtpLog.service';
import { ClientMatterSet_Service } from './clientMatterSet.service';
import { Service_Service } from './service.service';
import { ServiceCategory_Service } from './serviceCategory.service';
import { ServiceType_Service } from './serviceType.service';
import { ServiceAddress_Service } from './serviceAddress.service';
import { ToServe_Service } from './toServe.service';
import { Submission_Service} from './submission.service'
import { LegalConnect_Service } from './legalConnect.service';
import { Dictionary_Service } from './dictionary.service';
import { Document_Service } from './document.service';
import { Telemetry_Service } from './telemetry.service';
import { OrderService_Service } from './orderService.service';
import { OrderServiceAffidavit_Service } from './orderServiceAffidavit.service';
import { OrderServiceAttempt_Service } from './orderServiceAttempt.service';
import { OrderNote_service } from './orderNote.service';
import { OrderSpecialInstruction_Service } from './orderSpecialInstruction.service'
import { SuccessCountry_Service } from './country.service';
import { UserDevicePreferencesService } from './UserDevicePreferencesService.service';
import { PDFAnalysisTool_service } from './pdfAnalysisTool.service';
import { FieldLockService } from './fieldLock.service';


@NgModule({
    declarations: [

    ],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        CanLeaveService,
        ClientCode_Service,
        Project_BillingRateItem_Service,
        Project_ConflictParty_Service,
        ProjectBillingRate_Template_Service,
        ProjectBillingRate_TemplateItem_Service,
        ProjectType_Service,
        ProjectStatus_Service,
        User_Service,
        ClientType_Service,
        InvoiceTerm_Service,
        Client_Service,
        ClientAddress_Service,
        Case_Service,
        RateTemplate_Service,
        Contact_Service,
        ContactType_Service,
        Project_Service,
        FileType_Service,
        CaseFile_Service,
        ContactFile_Service,
        ClientFile_Service,
        ProjectFile_Service,
        FileAction_Service,
        PartnerVendor_Service,
        Note_Service,
        CaseNote_Service,
        ContactNote_Service,
        ClientNote_Service,
        ProjectNote_Service,
        UserCase_Service,
        UserProject_Service,
        CaseContact_Service,
        ProjectContact_Service,
        PartnerVendorClient_Service,
        UserIdentity_Service,
        PartnerVendorNotes_Service,
        File_Service,
        VendorType_Service,
        Billing_Service,
        ModelLibrary_Service,
        Order_Service,
        AdminOrder_Service,
        Invoice_Service,
        AdminInvoice_Service,
        OrderDetail_Service,
        PermissionNode_Service,
        Role_Service,
        RolePermissionNode_Service,
        ServiceAddress_Service,
        SuccessAccount_Service,
        SystemConfig_service,
        Telemetry_Service,
        SuccessAccountFirm_Service,
        SuccessAccountPermissionNode_Service,
        SuccessAccountRole_Service,
	    UserPermission_Service,
        VendorAccount_Service,
        Vendor_Service,
        ApiHealth_Service,
        CommonData_Service,
        AddressBookService,
        ImpersonateService,
        SuccessAccountSettingsService,
        OrderCache_Service,
        Mailing_Service,
        SuccessCase_Service,
        SuccessCaseContact_Service,
        SuccessContact_Service,
        SuccessCaseAccess_Service,
        Notification_Service,
        NotificationType_Service,
        Service_Service,
        ServiceCategory_Service,
        ServiceType_Service,
        FLCompany_Service,
        FLCompanyBranch_Service,
        ClientPartner_Service,
        ClientPartnerVendor_Service,
        OrderContact_Service,
        ClientUser_Service,
        ClientMatterEntry_Service,
        ClientMatterFtpLog_Service,
        ClientMatterSet_Service,
        ToServe_Service,
        Submission_Service,
        LegalConnect_Service,
        Dictionary_Service,
        Document_Service,
        OrderService_Service,
        OrderServiceAffidavit_Service,
        OrderServiceAttempt_Service,
        OrderNote_service,
        OrderSpecialInstruction_Service,
        SuccessCountry_Service,
        UserDevicePreferencesService,
        PDFAnalysisTool_service,
        FieldLockService
    ],
    exports: [

    ]
})
export class ServicesModule { }
