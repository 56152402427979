import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';


export class ServiceAddress extends DexResourceModel {

    @Field(String)
    public order_uuid: string | null = null;

    @Field(String)
    public address: string | null = null;

    @Field(String)
    public city: string | null = null;

    @Field(String)
    public state: string | null = null;

    @Field(String)
    public zip: string | null = null;

    @Field(String)
    public name: string | null = null;

    @Field(String)
    public phone: string | null = null;

    @Field(Number)
    public phoneExt: number | null = null;

    @Field(String)
    public room: string | null = null;

    @Field(String)
    public attention: string | null = null;

    @Field(String)
    public toServe_uuid: string | null = null;

    @Field(Date)
    public createdAt: Date = new Date();
    @Field(Date)
    public updatedAt: Date = new Date();
    @Field(Date)
    public deletedAt: Date | null = null;

    @Field(String)
    public signature: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }

}
