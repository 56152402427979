
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { ResourceService } from '@smartsoftware/reflex-core';
import { Role } from '../models/role.model';
import { BehaviorSubject, Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Page, PageParams } from '../paged.datasource';

@Injectable()
export class Role_Service extends ResourceService<Role> {

    protected servicePath = '/Role';

    private _isDataSynced = false;
    get isDataSynced(): boolean {
        return this._isDataSynced;
    }
    set isDataSynced(status: boolean) {
        this._isDataSynced = status;
        this.dataSync.next(this._isDataSynced);
    }

    private _defaultRoles: Role[] = [];
    get defaultRoles(): Role[] {
        return this._defaultRoles;
    }
    set defaultRoles(data: Role[]) {
        this._defaultRoles = data;
    }

    // private _roles: Role[] = [];
    // get roles(): Role[] {
    //     return this._roles;
    // }
    // set roles(data: Role[]) {
    //     this._roles = data;
    // }

    public ModelType = Role;
    public dataSync: BehaviorSubject<boolean> = new BehaviorSubject(this._isDataSynced);

    public push(entry: Role): Observable<Role> {
        
        if(!entry.name) {
            entry.name = "";
        }
        if(!entry.corpId) {
            entry.corpId = null;
        }
        
        return super.push(entry);
    }

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
  
    public syncRoleData() : void {
        this.getDefaultRoles()
        .then(
            (response: any) => {
                this.isDataSynced = true;
            }
        )

    }

    page(pageParams: PageParams): Observable<Page<Role>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
        return this.http.get<Page<Role>>(`${this.serviceUrl}successPage`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new Role(i));
                return page;
            }));
    }
    
    // public allRoles(): any {
    //     return this
    //         .http
    //         .get(
    //             this.serviceUrl,
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             tap((response: any) => {
    //                 for(let x in response.body)
    //                 {
    //                     if(response.body[x].deletedAt == null){
    //                         this.roles.push(response.body[x])
    //                     }
    //                 }
    //             })
    //         )   
    //         .toPromise();
    // }

    public getRolesForCorpId(data: {corpId: string}): Observable<Array<Role>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getRolesForCorpId',
                {
                    corpId: data.corpId,
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response.body.result.map((e: Role) => new Role(e))
                    }
                )
            );
    }

    public getDefaultRoles(): Promise<Array<Role>> {
        return this
            .http
            .post(
                this.serviceUrl + 'defaultRoles',
                {},
                {
                    observe: 'response'
                }
            )
            .pipe(
                map((response: any) => {
                    if(response && response.body) {
                        this.defaultRoles = response.body.result.map((e:Role) => new Role(e));
                        return this.defaultRoles;
                    } else {
                        return [];
                    }
                })
            )   
            .toPromise();
    }    

    public getClientRoles(): Observable<Role[]> {
        return this
            .http
            .get(
                this.serviceUrl + 'clientRoles',
                {
                    observe: 'response'
                }
            )
            .pipe(
                map((response: any) => {
                    if(response && response.body.result) {
                        return response.body.result;
                    } else {
                        return [];
                    }
                })
            );
    }
    
    public getRole(name?: string, uuid?: string) {

        let identity = name? {name: name} : {uuid:uuid};

        return this
            .http
            .post(
                this.serviceUrl + 'getRole',
                identity,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) =>
                        response.body.map((e: Role) => new Role(e))

                )
            );
    }

    public getRolesBySuccessAccount(data: {successAccount_uuid: string}): Observable<Array<Role>> {
        return this
            .http
            .post(
                this.serviceUrl + 'getRolesBySuccessAccount',
                {
                    successAccount_uuid: data.successAccount_uuid,
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        if(Array.isArray(response.body.result))
                        return response.body.result.map((e: Role) => new Role(e))
                    }
                )
            );
    }

}
