
import { Field } from "@smartsoftware/reflex-core";
import { DexResourceModel } from "../abstracts/dexResource.model";


export class Region extends DexResourceModel
{

    @Field(String)
    public name: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
}
