import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { SyncLog } from "../models/syncLog.model";
import { Page, PageParams } from "../paged.datasource";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class SyncLog_Service extends ResourceService<SyncLog> {
    protected servicePath =  '/SyncLog';

    public ModelType = SyncLog;
 
    constructor(
        protected http: HttpClient
    ){
        super(http);
    }

    page(pageParams: PageParams): Observable<Page<SyncLog>> {
        let params = new HttpParams()
            .append('params', btoa(JSON.stringify(pageParams)));
    
        return this.http.get<Page<SyncLog>>(`${this.serviceUrl}Page`, { params })
            .pipe(map(page => {
                page.items = page.items.map(i => new SyncLog(i));
                return page;
            }));
    }

}