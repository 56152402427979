import { Injectable } from "@angular/core";
import { 
    AddressBook, 
    Order,
    ClientMatterSet,
    ClientMatterEntry, 
    SuccessCase, 
    Notification, 
    NotificationType,
    Client, 
    SuccessContact, 
    SuccessAccount, 
    Invoice, 
    FLCompany,
    Document,
    SystemConfig,
    ClientMatterFtpLog,
    ServiceType,
    ServiceCategory,
    Service,
    FLCompanyBranch,
    SyncLog,
    Role,
    PermissionNode
} from "./models";
import { PagedDataSource } from "./paged.datasource";
import { 
    AddressBookService, 
    AdminOrder_Service,
    ClientMatterSet_Service, 
    ClientMatterEntry_Service,
    SuccessCase_Service, 
    Notification_Service, 
    NotificationType_Service, 
    Client_Service,
    SuccessContact_Service, 
    SuccessAccount_Service,
    AdminInvoice_Service,
    Invoice_Service,
    FLCompany_Service,
    Document_Service,
    SystemConfig_service,
    Service_Service,
    ServiceCategory_Service,
    ServiceType_Service,
    ClientMatterFtpLog_Service,
    FLCompanyBranch_Service,
    Order_Service,
    SyncLog_Service,
    Role_Service,
    PermissionNode_Service
} from "./services";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn:'root'})
export class OrderDataSource extends PagedDataSource<Order> {
    constructor(private orderService: AdminOrder_Service) {
        super(orderService);
        this.pageSize = 50;
    }
}

@Injectable({providedIn:'root'})
export class SuccessOrderDataSource extends PagedDataSource<Order> {
    constructor(private orderService: Order_Service) {
        super(orderService);
        this.pageSize = 50;
        this._loading = new BehaviorSubject<boolean>(true);
    }
}

@Injectable({providedIn:'root'})
export class InvoiceDataSource extends PagedDataSource<Invoice> {
    constructor(private service: AdminInvoice_Service) {
        super(service);
        this.pageSize = 50;
        this._loading = new BehaviorSubject<boolean>(true);
    }
}

@Injectable({providedIn:'root'})
export class InvoiceFilterDataSource extends PagedDataSource<Invoice> {
    constructor(private service: Invoice_Service) {
        super(service);
        this.pageSize = 10;
        this._loading = new BehaviorSubject<boolean>(true);
    }
}

@Injectable({providedIn:'root'})
export class AddressBookDataSource extends PagedDataSource<AddressBook> {
    constructor(private service: AddressBookService) {
        super(service);
    }
}

@Injectable({providedIn:'root'})
export class ClientMatterSetDataSource extends PagedDataSource<ClientMatterSet> {
    constructor(private service: ClientMatterSet_Service) {
         super(service);
         this.pageSize = 25;
    } 
}

@Injectable({providedIn:'root'})
export class ClientMatterEntryDataSource extends PagedDataSource<ClientMatterEntry> {
    constructor(private service: ClientMatterEntry_Service) {
        super(service);
    }
}

@Injectable({providedIn:'root'})
export class GeneralClientMatterEntryDataSource extends PagedDataSource<ClientMatterEntry> {
    constructor(private service: ClientMatterEntry_Service) {
        super(service);
    }
}

@Injectable({providedIn:'root'})
export class ClientMatterFtpLogDataSource extends PagedDataSource<ClientMatterFtpLog> {
    constructor(private service: ClientMatterFtpLog_Service) {
        super(service);
    }
}

@Injectable({providedIn:'root'})
export class ClientDataSource extends PagedDataSource<Client> {
    constructor(private service: Client_Service) {
         super(service);
         this.pageSize = 50;
    } 
}

@Injectable({providedIn:'root'})
export class SuccessCaseDataSource extends PagedDataSource<SuccessCase> {
    constructor(private service: SuccessCase_Service) {
        super(service);
    }
}

@Injectable({providedIn:'root'})
export class NotificationDataSource extends PagedDataSource<Notification> {
    constructor(private service: Notification_Service) {
        super(service);
        this.pageSize = 50;
    }
}

@Injectable({providedIn:'root'})
export class NotificationTypeDataSource extends PagedDataSource<NotificationType> {
    constructor(private service: NotificationType_Service) {
        super(service);
        this.pageSize = 50;
    }
}


@Injectable({providedIn:'root'})
export class SuccessContactDataSource extends PagedDataSource<SuccessContact> {
    constructor(private service: SuccessContact_Service) {
        super(service);
    }
    
}

@Injectable({providedIn:'root'})
export class SuccessAccountDataSource extends PagedDataSource<SuccessAccount> {
    constructor(private service: SuccessAccount_Service) {
        super(service);
    }
    
}

@Injectable({providedIn:'root'})
export class FLCompanyDataSource extends PagedDataSource<FLCompany> {
    constructor(private service: FLCompany_Service) {
        super(service);
        this.pageSize = 25;
    }
}

@Injectable({providedIn:'root'})
export class DocumentDataSource extends PagedDataSource<Document> {
    constructor(private service: Document_Service) {
        super(service);
        this.pageSize = 25;
    }
}

@Injectable({providedIn:'root'})
export class SystemConfigDataSource extends PagedDataSource<SystemConfig>
{
    constructor(private service: SystemConfig_service)
    {
        super(service);
        this.pageSize = 25;
    }
}

@Injectable({providedIn:'root'})
export class ServiceDataSource extends PagedDataSource<Service> {
    constructor(private service: Service_Service) {
        super(service);
        this.pageSize = 25;
    }
}

@Injectable({providedIn:'root'})
export class ServiceCategoryDataSource extends PagedDataSource<ServiceCategory> {
    constructor(private service: ServiceCategory_Service) {
        super(service);
        this.pageSize = 25;
    }
}

@Injectable({providedIn:'root'})
export class ServiceTypeDataSource extends PagedDataSource<ServiceType> {
    constructor(private service: ServiceType_Service) {
        super(service);
        this.pageSize = 25;
    }
}

@Injectable({providedIn:'root'})
export class FLCompanyBranchDataSource extends PagedDataSource<FLCompanyBranch> {
    constructor(private branchService: FLCompanyBranch_Service) {
        super(branchService);
        this.pageSize = 25;
    }
}

@Injectable({providedIn: 'root'})
export class SyncLogDataSource extends PagedDataSource<SyncLog> {
    constructor(private syncLogService: SyncLog_Service) {
        super(syncLogService);
        this.pageSize = 25;
    }
}

@Injectable({providedIn:'root'})
export class RoleDataSource extends PagedDataSource<Role> {
    constructor(private roleService: Role_Service) {
        super(roleService);
        this.pageSize = 25;
    }
}

@Injectable({providedIn:'root'})
export class PermissionNodeDataSource extends PagedDataSource<PermissionNode> {
    constructor(private permissionNodeservice: PermissionNode_Service) {
        super(permissionNodeservice);
        this.pageSize = 25;
    }
}
