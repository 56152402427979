<mat-tab-group *ngIf="entity && !entity.uuid && newUser">
	<mat-tab label="New User Profile">
		<resource-list-grid>
			<resource-list-pane title="New User">
				<form *ngIf="entityForm.controls" [formGroup]="entityForm">

					<div class="row">
						<mat-form-field>
							<mat-label>Email Address</mat-label>
							<input 
								required 
								matInput 
								type="email" 
								maxlength="255"
								formControlName="email" 
								placeholder="Email" />
								<mat-error *ngIf="entityForm.controls.email.hasError('email')">
									Invalid Email Address
								</mat-error>
								<mat-error *ngIf="entityForm.controls.email.hasError('unique')">
									An account already exists with this email address
								</mat-error>
						</mat-form-field>
					
						<mat-form-field>
							<mat-label>User Name</mat-label>
							<input 
								required
								matInput 
								maxlength="50"
								formControlName="username" />
								<mat-error *ngIf="entityForm.controls.username.hasError('unique')">An account already exists with this username</mat-error>
								<mat-error *ngIf="entityForm.controls.username.hasError('minlength')">Must be at least 2 characters.</mat-error>
								<mat-error *ngIf="entityForm.controls.username.hasError('pattern')">{{USERNAME_HINT}}</mat-error>
								<mat-hint>{{USERNAME_HINT}}</mat-hint>
	
						</mat-form-field>
					
					</div>
					
					<div class="row">
						<mat-form-field>
							<mat-label>First Name</mat-label>
							<input 
								required
								matInput 
								maxlength="255"
								formControlName="firstName" />
						</mat-form-field>
					
						<mat-form-field>
							<mat-label>Last Name</mat-label>
							<input 
								required
								matInput 
								maxlength="255"
								formControlName="lastName" />
						</mat-form-field>
					</div>
			
					<div class="row">
						<mat-form-field>
                            <input matInput
                                type="number"
                                step="1"
                                min="1"
                                maxlength="255"
                                [matAutocomplete]="companyAutoComplete"
                                (focusin)="companyNumberFocus.next($event)"
                                (focusout)="companyNumberFocus.next($event)"
                                required
                                formControlName="companyNumber"
                                placeholder="Company Number"
                                 />
                            <mat-autocomplete 
                                 [displayWith]="getCompanyNumber"
                                 #companyAutoComplete="matAutocomplete">
                                 <mat-option *ngFor="let option of companyNumberSuggestions | async" [value]="option">
                                     {{option}}
                                 </mat-option>
                             </mat-autocomplete>
                             <mat-error *ngIf="entityForm.controls.companyNumber?.errors?.required">Field is Required</mat-error>
                             <mat-error *ngIf="entityForm.controls.companyNumber?.errors?.companyNumber">Invalid</mat-error>
                             <mat-hint *ngIf="companyNumberControl?.warnings?.companyNumber">Warning Possibly Invalid</mat-hint>
                             <mat-error *ngIf="entityForm.controls.companyNumber?.errors?.dependsOn">Requires a valid Corp Id</mat-error>
                             <mat-error *ngIf="entityForm.controls.companyNumber?.errors?.min">Must be positive</mat-error>
                             <mat-error *ngIf="entityForm.controls.companyNumber?.errors?.pattern">Must be an integer</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput
                                type="number"
                                step="1"
                                min="1"
                                maxlength="255"
                                [matAutocomplete]="customerAutoComplete"
                                (focusin)="customerNumberFocus.next($event)"
                                required
                                formControlName="customerNumber"
                                placeholder="Customer Number"
                                />
                            <mat-autocomplete 
                                [displayWith]="getCustomerNumber"
                                #customerAutoComplete="matAutocomplete">
                                <mat-option *ngFor="let option of customerNumberSuggestions | async" [value]="option.customerNumber">
                                    {{option.customerNumber}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-hint *ngIf="customerNumberControl?.warnings?.customerNumber">Warning Possibly Invalid</mat-hint>
                            <mat-error *ngIf="entityForm.controls.customerNumber?.errors?.required">Field is Required</mat-error>
                            <mat-error *ngIf="entityForm.controls.customerNumber?.errors?.customerNumber">Invalid</mat-error>
                            <mat-error *ngIf="entityForm.controls.customerNumber?.errors?.dependsOn">Requires a valid Company Number and Corp Id</mat-error>
                            <mat-error *ngIf="entityForm.controls.customerNumber?.errors?.min">Must be positive</mat-error>
                            <mat-error *ngIf="entityForm.controls.customerNumber?.errors?.pattern">Must be an integer</mat-error>
                        </mat-form-field>
					</div>
				</form>
			</resource-list-pane>
			<resource-list-pane>
				<form *ngIf="entityForm.controls" [formGroup]="entityForm">
					<lib-change-password *ngIf="entity" 
						[user]="entity" 
						(onPasswordValueChange)="entityForm.controls.password.setValue($event)">
					</lib-change-password>
				</form>
			</resource-list-pane>
		</resource-list-grid>
	</mat-tab>
</mat-tab-group>

<mat-tab-group *ngIf="entity && entity.uuid" [hidden]="isLoading">
	<mat-tab label="Profile">
		<resource-list-grid>
			<resource-list-pane title="Contact Information">
				<form [formGroup]="entityForm">

					<div class="row">
						<mat-form-field>
							<mat-label>First Name</mat-label>
							<input 
								matInput 
								maxlength="255"
								formControlName="firstName" />
						</mat-form-field>
					
						<mat-form-field>
							<mat-label>Last Name</mat-label>
							<input matInput 
								maxlength="255"
								formControlName="lastName" />
						</mat-form-field>
					</div>
				
				</form>
					<div class="row">
						<mat-form-field>
							<mat-label>Email Address</mat-label>
							<input required 
								matInput 
								readonly
								type="email" 
								[(ngModel)]="entity.email" />
								<mat-hint>Contact support to change your email address</mat-hint>
						</mat-form-field>
					</div>

					<mat-form-field>
						<mat-label>Mobile Phone</mat-label>
						<input phone matInput type="tel" [(ngModel)]="settings.mobilePhone" />
					</mat-form-field>
					<div class="row">
						<mat-form-field class="grow-3">
							<mat-label>Office Phone</mat-label>
							<input phone matInput type="tel" [(ngModel)]="settings.businessPhone" />
						</mat-form-field>
						<mat-form-field >
							<mat-label>Extension</mat-label>
							<input matInput type="number" maxlength="9" [(ngModel)]="settings.businessPhoneExtension" />
						</mat-form-field>
					</div>

			</resource-list-pane>
			
			<resource-list-pane title="Default Insurance Information">
				<mat-checkbox disabled aria-label="Same as my organization's default insurance" [checked]="true" matTooltip="This is not yet available for you to customize">Same as my organization's default insurance</mat-checkbox>
			</resource-list-pane>
		
		</resource-list-grid>
	</mat-tab>

	<mat-tab label="Preferences" *ngIf="settings">
		<resource-list-grid>
			<resource-list-pane title="Notifications">
				<p>These preferences represent your defaults, but can be customized on a case-by-case and job-by-job basis if needed.</p>

				<lib-notification-preferences [user]="entity" [settings]="settings"></lib-notification-preferences>

			</resource-list-pane>
			<resource-list-pane title="Default Special Instructions">
				<p>These can (and are likely to) be overridden on a case-by-case and job-by-job basis, but are the defaults when creating a new job.</p>

				<lib-special-instruction-preferences [user]="entity" [settings]="settings"></lib-special-instruction-preferences>
			</resource-list-pane>
		</resource-list-grid>
	</mat-tab>

	<mat-tab label="User Permissions">
		<resource-list-grid *ngIf="!isCurrentUser">
			<resource-list-pane title="Roles">
				<mat-selection-list #roleSelectionList>
					<mat-list-option color="primary" *ngFor="let role of currentRoles" [value]="role">
					  {{role.name}}
					</mat-list-option>
				</mat-selection-list>
			</resource-list-pane>

			<resource-list-pane title="Override Permissions">
				<mat-selection-list #permissionSelectionList>
					<mat-list-option color="primary" *ngFor="let permission of currentPermissions" [value]="permission">
					  {{permission.description}} - {{permission.code}}
					</mat-list-option>
				</mat-selection-list>
			</resource-list-pane>
		</resource-list-grid>

		<resource-list-grid *ngIf="isCurrentUser">
			<resource-list-pane title="Roles">
				<mat-table [dataSource]="roleSource">
					<ng-container cdkColumnDef="name">
						<mat-header-cell *cdkHeaderCellDef>Role</mat-header-cell>
						<mat-cell *cdkCellDef="let entity">{{entity.name}}</mat-cell>
					</ng-container>
					<mat-header-row *cdkHeaderRowDef="['name']; sticky: true"></mat-header-row>
					<mat-row *cdkRowDef="let row; columns: ['name']"></mat-row>
				</mat-table>
			</resource-list-pane>

			<resource-list-pane title="Permissions">
				<mat-table [dataSource]="permissionSource">
					<ng-container cdkColumnDef="description">
						<mat-header-cell *cdkHeaderCellDef>Description</mat-header-cell>
						<mat-cell *cdkCellDef="let entity">{{entity.description}}</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="code">
						<mat-header-cell *cdkHeaderCellDef>Code</mat-header-cell>
						<mat-cell *cdkCellDef="let entity">{{entity.code}}</mat-cell>
					</ng-container>

					<mat-header-row *cdkHeaderRowDef="['code','description']; sticky: true"></mat-header-row>
					<mat-row *cdkRowDef="let row; columns: ['code','description']"></mat-row>
				</mat-table>
			</resource-list-pane>
		</resource-list-grid>
	</mat-tab>

	<mat-tab label="Security">
		<resource-list-grid>
			<resource-list-pane title="Account Status">

				<mat-slide-toggle disabled [checked]="entity?.active" #toggle [matTooltip]="dictionary.tooltip.editUser_accountStatus">
					Account {{toggle.checked ? 'Enabled' : 'Disabled'}}
				</mat-slide-toggle>
			</resource-list-pane>
			<resource-list-pane title="Change Password">
				<lib-change-password kind="owner" [user]="entity"></lib-change-password>
			</resource-list-pane>
		</resource-list-grid>

	</mat-tab>
</mat-tab-group>
