<resource-list-grid *ngIf="entity">
    <resource-list-pane title="Address Book">
        <form #editForm="ngForm" (ngSubmit)="save()" [formGroup]="entityForm" *ngIf="entityForm !== undefined">
            <mat-button-toggle-group style="width:min-content"
            [formControl]="privateCtrl"
            (change)="setBreadCrumbs()"
            color="primary">
                <mat-button-toggle value="private">Private </mat-button-toggle>
                <mat-button-toggle value="shared">Shared </mat-button-toggle>
                <mat-button-toggle disabled>
                    <span style='color:black;'>Address is {{privateCtrl.value == 'private' ? "visible only to current user" : "visible to entire firm"}} </span>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <br>
            <br>
            <div class="entity-row">
                <mat-form-field>
                    <mat-label>Care Of Line</mat-label>
                    <input matInput
                        (change)="setBreadCrumbs()"
                        formControlName="careOfLine"
                        maxlength="255"/>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput
                        required
                        (change)="setBreadCrumbs()"
                        formControlName="name"
                        maxlength="255"/>
                        <mat-error *ngIf="this.entityForm.controls['name'].hasError('required')">Please enter a value</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Address</mat-label>
                    <input matInput
                        required
                        (change)="setBreadCrumbs()"
                        formControlName="streetAddress1"
                        maxlength="255"/> 
                        <mat-error *ngIf="this.entityForm.controls['streetAddress1'].hasError('required')">Please enter a value</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Room Number/Suite</mat-label>
                    <input matInput
                        (change)="setBreadCrumbs()"
                        formControlName="roomNumber"
                        maxlength="10"/> 
                        <mat-error *ngIf="this.entityForm.controls['roomNumber'].hasError('required')">Please enter a value</mat-error>
                </mat-form-field>
            </div>
            <br>
            <div class="entity-row">
                <mat-form-field>
                    <mat-label>Country</mat-label>
                    <mat-select
                        [value]=""
                        required
                        formControlName="country"
                        (selectionChange)="setBreadCrumbs()">
                        <mat-option *ngFor="let country of locationService.countries" value="{{country.value}}">{{country.label}}, {{country.value}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.entityForm.controls['country'].hasError('required')">Please enter a value</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>City</mat-label>
                    <input matInput
                        required
                        (change)="setBreadCrumbs()"
                        formControlName="city"
                        maxlength="255"/> 
                        <mat-error *ngIf="this.entityForm.controls['city'].hasError('required')">Please enter a value</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="this.entityForm.controls['country'].value == 'US'" >
                    <mat-label>State</mat-label>
                    <mat-select 
                        required
                        formControlName="state"
                        (selectionChange)="setBreadCrumbs()">
                        <mat-option *ngFor="let state of locationService.states" value="{{state.abbreviation}}">{{state.name}}, {{state.abbreviation}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.entityForm.controls['state'].hasError('required')">Please select a value</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Postal Code</mat-label>
                    <input matInput
                        required
                        (change)="setBreadCrumbs()"
                        formControlName="postalCode"
                        maxlength="10"/> 
                        <mat-error *ngIf="this.entityForm.controls['postalCode'].hasError('required')">Please enter a value</mat-error>
                </mat-form-field>
            </div>
            <br>
            <div class="entity-row">
                <mat-form-field>
                    <mat-label>Phone Number</mat-label>
                    <input matInput
                        required
                        type="tel"
                        pattern="^[0-9\-\()\s]*$"
                        (change)="setBreadCrumbs()"
                        formControlName="primaryPhoneNumber"/> 
                        <mat-error *ngIf="this.entityForm.controls['primaryPhoneNumber'].hasError('required')">Please enter a value</mat-error>
                    </mat-form-field>
                <mat-form-field>
                    <mat-label>Phone Extension</mat-label>
                    <input matInput
                        type="tel"
                        pattern="^[0-9\-\()\s]*$"
                        (change)="setBreadCrumbs()"
                        formControlName="phoneExt"/> 
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Fax Number</mat-label>
                    <input matInput
                        type="tel"
                        pattern="^[0-9\-\()\s]*$"
                        (change)="setBreadCrumbs()"
                        formControlName="primaryFaxNumber"/> 
                </mat-form-field>
            </div>
        </form>        
    </resource-list-pane>
</resource-list-grid>
