import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'datatrac-failure',
  templateUrl: 'datatracFailure.html',
  styleUrls: ['./datatracFailure.scss']
})
export class DatatracFailureDialog {

    constructor(
        private dialogRef : MatDialogRef<DatatracFailureDialog>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {
        console.debug(data);
    }

    close() {
        this.dialogRef.close(true);
    }
}