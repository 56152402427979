import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDeleteDialog, DownloadDialog, ErrorDialog, ReflexEnvironment, ResourceModel, ResourceService } from '@smartsoftware/reflex-core';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Filters, PagedDataSource } from '../../paged.datasource';
import { UserDevicePreferencesService } from '../../services';

@Component({
  selector: 'lib-paged-resource-list',
  templateUrl: './paged-resource-list.component.html',
  styleUrls: ['./paged-resource-list.component.css'],
})
export class PagedResourceListComponent<T extends ResourceModel> implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort?: MatSort;
  @ViewChildren(MatPaginator) paginators?: QueryList<MatPaginator>;
  filterFormGroup?: FormGroup;
  selection = new SelectionModel<T>(true, []);

  constructor(
    public dataSource: PagedDataSource<T>,
    protected resourceService: ResourceService<T>,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected router: Router,
    protected userDevice?: UserDevicePreferencesService
  ) { 
    
  }
    


  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  ngOnInit(): void {

  }

  openConfirmDeleteDialog() {
    return this.dialog
        .open(ConfirmDeleteDialog, {
        data: { entities: this.selection.selected }
    })
    .afterClosed();
  }

  openErrorDialog(error:any) {
    return this.dialog
        .open(ErrorDialog, {
        data: { error: error }
    })
        .afterClosed()
        .subscribe(response => {

    });
  }

  public refresh() {
    this.dataSource.reload();
  }

  public download() {
    let entityList = this.dataSource.data;
    this.dialog
        .open(DownloadDialog, {
        data: { entityList }
    });
  }
  public new() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }
  
  public delete() {
    this
        .openConfirmDeleteDialog()
        .subscribe(
            response => {
                if (response.confirmed) {
                    this.deleteWithConfirm();
                }
            }
        )
  }

  public deleteWithConfirm() {
    let observables = this.selection.selected.map((entity: T) => {
      entity.isPublished = false;
      entity.isDeleted = true;
      return this.resourceService.push(entity);
    });
    combineLatest(observables).pipe(
      tap(_ => {
        this.selection.clear();
        this.dataSource.reload();
      })
    )
      .subscribe({
        error: error => this.openErrorDialog(error)
    });
  }

  allSelected() {
    const a = this.selection.selected.length;
    const b = this.dataSource.data.length;
    return a === b;
  }

  selectAll() {
      this.allSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(entity => this.selection.select(entity));
  }
  
  doAction(eventName: string) {
    let self = this as any;
    return eventName in self && typeof self[eventName] === 'function' ? self[eventName]() : null;
  }
 
  /**
   * Transforms the control value to a value on the datasource's filters mapping
   * @param name name of the control in the `filterFormGroup`
   * @param control 
   * @returns 
   */
  filterValue(name: string, control: AbstractControl): any {
    return control.value;
  }

  /**
   * Given the filter's key name, returns the value that should be set on the `filterFormGroup`'s control(s)
   * @param filterName the key name in the datasource's filters mapping 
   * @returns 
   */
  controlValue(filterName: string): any {
    return this.dataSource.filters[filterName];
  }

  /**
   * Given the filter's key name, returns the associated `filterFormGroup`'s control names
   * @param name the key name in the datasource's filters mapping 
   * @returns 
   */
  controlNamesForFilter(name: string): string[] {
    return [name];
  }

  /**
   * 
   * @param name the key name in the `FilterFormGroup` controls
   * @returns the key name in the datasource's filters mapping 
   */
  filterNameForControl(name: string): string {
    return name;
  }

  /**
   * Converts/transforms all the `filterFormGroup` control values to the datasource's filter values, 
   * creating a new filters mapping and setting it on the `dataSource`, which will trigger
   * the `dataSource` reload.
   */
  onFilterSubmit() {
    if (this.filterFormGroup) {
      // watch for submission, make Filters instance and pass to dataSource
      let filters: Filters = {};
      for (let key in this.filterFormGroup.controls) {
        let control = this.filterFormGroup.controls[key];
        if (control.valid) {
          let k = this.filterNameForControl(key);
          let value = this.filterValue(key, control);
          if (k && value || k && value === null) {
            filters[k] = value;
          } 
        }
      }
      // triggers loadData on dataSource
      this.dataSource.filters = filters;
    }
  }

  /**
   * Clears all the control values in `filterFormGroup`, as well as clearing
   * the filters mapping in `dataSource`. Will trigger the `dataSource` reload
   */
  clearFilterForm() {
    if (this.filterFormGroup) {  
      for (let key in this.filterFormGroup.controls) {
        let control = this.filterFormGroup.controls[key];
        control.setValue(undefined);
      }
      let filters: Filters = {};
      // triggers loadData on dataSource
      this.dataSource.filters = filters;
    }
  }
  
  ngAfterViewInit(): void {
    // delay to process on next event loop cycle
    // prevents the ExpressionChangedAfterItHasBeenCheckedError 
    setTimeout(() => { 
      
      if (this.filterFormGroup) {
        // synchronize the `filterFormGroup` control values to the `dataSource`'s filters mapping
        // so that they will be displayed to the user
        for (let key in this.dataSource.filters) {
          for (let controlName of this.controlNamesForFilter(key)) {
            let control = this.filterFormGroup.get(controlName);
            if (control) {
              control.setValue(this.controlValue(key));
            }
          }
        }
      }

      // doing this in ngAfterViewInit, since the MatSort and MatPaginator instances will be initialized
      // hook up the MatSort and MatPaginator with the `dataSource`
      this.dataSource.sort = this.sort || null;
      if (this.paginators) {
        this.dataSource.paginator = this.paginators.first;
        if (this.paginators.first != this.paginators.last) {
          this.dataSource.otherPaginator = this.paginators.last;
        }
      }
    });
  }
}
