import { Component, ViewChild, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog} from '@angular/material/dialog';
import { ResourceListComponent, ResourceListPane } from '@smartsoftware/reflex-core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import {
    AddressBook,
    AddressBookService,
    UserPermission_Service,
    SuccessAccountRoleName,
    PagedResourceListComponent,
    AddressBookDataSource,
    SystemConfig_service,
    UserDevicePreferencesService
} from 'legalreflex-lib';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AddressRestoreDialog } from '../restore/addressRestoreDialog';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    templateUrl: './list.html',
    styleUrls: ['./list.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('filterExpand', [
            state('collapsed', style({width: '0px', minWidth: '0'})),
            state('expanded', style({width: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class AddressList extends PagedResourceListComponent<AddressBook> {

    @ViewChild(MatSort) sort: MatSort = new MatSort();
    @ViewChild(MatTable) table: MatTable<any> | undefined;
    @ViewChild('filter') filterPane: ResourceListPane = new ResourceListPane();
    public filterCollapsed: boolean = sessionStorage.getItem("filterCollapsed") == 'true';
    
    public accountId: string = '';
    public corpId: string = '';
    public isLoading: boolean = false;

    public expandedEntity: AddressBook | null = null;
    public expandedFilter: boolean = true;

    public addressColumns = ['select', 'view', 'name', 'streetAddress1', 'state', 'city', 'primaryPhoneNumber', 'isPrivate', 'isDefault']

    constructor(
        public dataSource: AddressBookDataSource,
        public resourceService: AddressBookService,
        public router: Router,
        public fb: FormBuilder,
        protected auth: OidcSecurityService,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        private userPermissionService: UserPermission_Service,
        private breadCrumbService: BreadCrumbService,
        public sysConfig: SystemConfig_service,
        public userDevicePreferences: UserDevicePreferencesService
    ) { 
        super(dataSource, resourceService, dialog, route, router);
        let filters = this.userDevicePreferences.fcFilter;
        Object.assign(filters, {corp_id: this.userDevicePreferences.fcFilter.corpId});
        this.filterFormGroup = this.fb.group({
            isPrivate: [false],
            isShared: [false],
            isDefault: [false],
            deletedAt: ['notDeleted'],
            name: [''],
            corp_id: [this.corpId],
            owner_uuid: [''],
            streetAddress1: [''],
            city: [''],
            state: [''],
            country: [''],
            postalCode: [''],
            addressType: [''],
            source: ['success'],
        })
        this.filterFormGroup.patchValue(filters);
        Object.keys(this.dataSource.filters).map((key)=>{this.dataSource.filters[key] = filters[key]})

        this.auth.userData$.subscribe(({userData, allUserData}) => {
			let response = userData;
            this.accountId = response.profile.uuid;
            this.corpId = response.profile.corpId;
        });

        this.setBreadCrumbs()
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        this.dataSource.sort = this.sort;
        this.dataSource.pageSize = 25;
    }

    ngAfterViewInit() {
        super.ngAfterViewInit()
        setTimeout(() => {
            this.filterFormGroup!.valueChanges.subscribe((jsonObject)=>{
                if(jsonObject.corp_id)
                    Object.assign(jsonObject, {corpId: jsonObject.corp_id});
                this.userDevicePreferences.fcFilter = jsonObject;
            })
        
            if (this.dataSource.sort) {
                this.dataSource.sort.active = 'name'
                this.dataSource.sort.direction = 'asc'
            }
            this.getFilter();
            let filterClick = document.getElementById('filterPaneAddressBook');
            if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                filterClick.setAttribute('filterClickAttached','true')
                filterClick.addEventListener("click",()=>{
                    sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                })
            }
        });
        this.dataSource.loading.subscribe((state)=>{
            this.isLoading = state
            this.setBreadCrumbs()
        })
    }

    selectionChange(event?:Event){
        if(event){
            event.stopPropagation()
        }
        // To fire after selection update goes through
        setTimeout(() => {
            this.setBreadCrumbs()
        })
        
    }

    pageChange(event:any){
        this.selection.clear()
        this.setBreadCrumbs()
    }

    sortData(event: any){
        this.selection.clear()
        this.setBreadCrumbs()
        this.dataSource.data = this.dataSource.sortData(this.dataSource.data,this.sort);
    }

    setBreadCrumbs(){

        let crumbs = []
        crumbs.push({ label: 'Address Book', url:'/address-book'})
        crumbs.push({ label: 'History'})
        this.breadCrumbService.breadcrumbs.next(crumbs)

        let actions = []
        
        actions.push({label: 'Refresh', action:this.getFilter.bind(this,false), icon: 'refresh'})
        if(!this.selection.isEmpty()){
            let defaultEntries = this.selection.selected.find((res)=>{
                return ((res.isDefault || res.addressType == 'COURT' || res.isDeleted))
            })
            if(defaultEntries){
                actions.push({label: 'Delete Address(es)', disabled:true, icon: 'delete', tooltip:"Please deselect any invalid addresses (default/court/deleted)."})
            }else{
                actions.push({label: 'Delete Address(es)', action:this.deleteEntries.bind(this), icon: 'delete'})
            }
        }else{
            actions.push({label: 'Delete Address(es)', disabled:true, icon: 'delete', tooltip:"Please select 1 or more addresses."})
        }
        actions.push({label: 'New Address', routerLink: "/address-book/new", isPrimary: true, icon: 'library_add'})
        this.breadCrumbService.actions.next(actions)
    }

    getFilter(reset?:boolean){

        if(reset){
            this.filterFormGroup = this.fb.group({
                isPrivate: [false],
                isShared: [false],
                isDefault: [false],
                deletedAt: ['notDeleted'],
                name: [''],
                corp_id: [this.corpId],
                owner_uuid: [''],
                streetAddress1: [''],
                city: [''],
                state: [''],
                country: [''],
                postalCode: [''],
                addressType: [''],
                source: ['success'],
            })
        }

        let privacy = ['isPrivate', 'isShared', 'isDefault']
        privacy.map((priv)=>{
            if(this.filterFormGroup?.controls[priv]){
                if(!this.filterFormGroup.controls[priv])
                    delete this.filterFormGroup?.controls[priv]
            }
        })

        if(this.filterFormGroup?.controls['deletedAt'].value)
            this.filterFormGroup?.controls['deletedAt'].setValue(this.filterFormGroup.controls['deletedAt'].value)
        else if(this.filterFormGroup?.controls['deletedAt'])
            this.filterFormGroup?.controls['deletedAt'].setValue('notDeleted')

        if(this.filterFormGroup?.controls['addressType'].value)
            this.filterFormGroup?.controls['addressType'].setValue(`COURT`)
        else if(this.filterFormGroup?.controls['addressType'])
            delete this.filterFormGroup?.controls['addressType']

        this.selection.clear()
        this.setBreadCrumbs()
        this.onFilterSubmit()

        privacy.map((priv)=>{
            if(!this.filterFormGroup?.controls[priv])
                this.filterFormGroup?.addControl(priv, new FormControl(false))
        })
        if(!this.filterFormGroup?.controls['addressType'])
            this.filterFormGroup?.addControl('addressType', new FormControl())
    }
    
    restoreEntry(entity: AddressBook){
        this.dialog.open(AddressRestoreDialog, {
            data: entity
        }).afterClosed().subscribe((res)=>{
            if(res){
                entity.deletedAt = null
                entity.isDeleted = false
                this.resourceService.push(entity).subscribe(() => {});
            }        
        })
    }

    deleteEntries(){ 
        let defaultEntries = this.selection.selected.find((res)=>{
            return ((res.isDefault || res.addressType == 'COURT'))
        })

        if(defaultEntries){
            console.warn("Default entries can not be deleted")
            return
        }

        this.openConfirmDeleteDialog().subscribe(response => {
            if (response.confirmed) {
                let observables = this.selection.selected.map((address)=>{
                    address.deletedAt = new Date()
                    address.isDeleted = true
                    return this.resourceService.push((address))
                })
                this.isLoading = true
                combineLatest(observables).pipe(
                    tap(_ => {
                        this.selection.clear()
                        this.setBreadCrumbs()
                        this.selection.clear()
                        this.onFilterSubmit()
                    })
                    )
                    .subscribe({
                        error: error => this.openErrorDialog(error)
                    });
            }
        })
    }
}