import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';


export class SequenceOrderStepType extends DexResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public displayName: string = "";

    @Field(Number)
    public stepNumber: number = 0;

    @Field(Boolean)
    public optional: boolean = false;

    @Field(String)
    public sequenceOrderType_uuid: string = "";

    @Field(String)
    public serviceCategory_uuid: string = "";

    @Field(String)
    public serviceType_uuid: string = "";

    @Field(Boolean)
    public ordersPerStep: boolean = false;

    @Field(String)
    public notificationType_uuid: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
}
