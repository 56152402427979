import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ServeManagerBackOfficeBusyErrors extends DexResourceModel {
    @Field(Number)
    public smOrderId: number | null = null;

    @Field(String)
    public dtControlNumber: string | null = null;

    @Field(Boolean)
    public createDTOrder: boolean = false;

	public constructor(jsonData?: { [key: string]: any; }) {
		// Constructor calls empty super
		super();

		// Map the data ourselves using the inherited function. If the parent
		// constructor maps it, typescript will overwrite our data with our
		// defaults afterwards.
		if (jsonData) {
			this.mapData(jsonData);
		}
	}
}
