import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';


export class SequenceOrderStep extends DexResourceModel {

    @Field(String)
    public parentStep_uuid: string | null = null;

    @Field(String)
    public order_uuid: string | null = null;

    @Field(String)
    public sequenceOrder_uuid: string = "";

    @Field(String)
    public sequenceOrderStepType_uuid: string = "";

    @Field(Boolean)
    public isCompleted: boolean = false;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get data(): any {
        let json = super.data;

        return json;
    }
}
