import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class SyncLog extends DexResourceModel {

    @Field(String)
    public message: string = "";
    @Field(String)
    public jsonData: string = "";

    @Field(String)
    public syncEntity_uuid: string = "";

    @Field(String)
    public syncVendorEntity_uuid: string = "";

    @Field(Boolean)
    public isEFileAvailable: boolean = false;

    @Field(String)
	public record_uuid: string = "";


    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
