


import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { Region } from "../models/region.model";
import { HttpClient } from '@angular/common/http'
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SuccessAccount_Service } from "./successAccount.service";

@Injectable()
export class Region_Service extends ResourceService<Region>
{
    protected servicePath: string = '/Region';
    public ModelType = Region;

    constructor( protected http: HttpClient, router: Router, account: SuccessAccount_Service )
    { 
        super(http); 
    }
}