import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ServeManagerOrderMapping extends DexResourceModel {
    @Field(Number)
    public smOrderId: number = 0;

    @Field(Number)
    public smJobNumber: number = 0;

    @Field(String)
    public accountNo: string | null = null;

    @Field(Number)
    public dtControlNumber: number = 0;

    @Field(Number)
    public dtCompanyNumber: number = 0;

    @Field(Number)
    public dtRecordType: number | null = 0;

    @Field(String)
    public dtId: string = "";

    @Field(Number)
    public totalFees: number | null = 0;

    @Field(Number)
    public advancedFees: number | null = 0;

    @Field(Boolean)
    public orderClosed: boolean = false;

    @Field(Boolean)
    public createDTOrder: boolean = false;

    @Field(Boolean)
    public isActive: boolean = true;

    @Field(Number)
    public AttemptCount: number | null = null;

    @Field(Number)
    public PageCount: number | null = null;

    @Field(Number)
    public LastPageCountSynced: number | null = null;

	public constructor(jsonData?: { [key: string]: any; }) {
		// Constructor calls empty super
		super();

		// Map the data ourselves using the inherited function. If the parent
		// constructor maps it, typescript will overwrite our data with our
		// defaults afterwards.
		if (jsonData) {
			this.mapData(jsonData);
		}
	}
}
