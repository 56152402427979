
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoLoginPartialRoutesGuard as AuthGuard } from 'angular-auth-oidc-client';
import { NavigationModule, ResourceModule } from '@smartsoftware/reflex-core';
import { AestheticsModule } from '../../aesthetics/aesthetics.module';
import { ComponentsModule } from '../../components/components.module';
import { LegalReflexDirectivesModule, LegalReflexComponentsModule,UserPermission_Service } from 'legalreflex-lib';

import { FrameComponent } from './frame/frame.component';
import { UserView } from './view/view';
import { SuccessAccountList } from './list/list.component';
import { SuccessAccountCreate } from './create/create';
import { IsAdmin } from '../../auth.guard';

const routes: Routes = [
  {
    path: 'users',
    component: FrameComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [AuthGuard,IsAdmin],
        component: SuccessAccountList
      },
      {
        path: "new",
        canActivate: [AuthGuard,IsAdmin],
        component: UserView
      },
      {
        path: ":id",
        canActivate: [AuthGuard,IsAdmin],
        component: UserView
      }
    ]
  }
];

@NgModule({
	declarations: [
    FrameComponent,
    UserView,
    SuccessAccountList,
    SuccessAccountCreate
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
    AestheticsModule,
		ResourceModule,
		BrowserModule,
		RouterModule,
		ComponentsModule,
		LegalReflexDirectivesModule,
		LegalReflexComponentsModule,
		RouterModule.forChild(routes)
	],
	exports: [

	],
	bootstrap: [

	]
})
export class UsersModule { }
