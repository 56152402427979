import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http'
import { ResourceService } from '@smartsoftware/reflex-core';
import { Client } from '../models/client.model';
import { ClientPartner } from '../models/clientPartner.model';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { Page, PageParams } from '../paged.datasource';
import { InvoiceLineItem } from '../models/invoiceLineItem.model';


@Injectable()
export class InvoiceLineItem_Service extends ResourceService<InvoiceLineItem> {
    protected servicePath: string = '/InvoiceLineItem';

    public ModelType = InvoiceLineItem;

    public getByInvoiceUuid(invoice_uuid: string) {
        return this.http.get<any[]>(`${this.serviceUrl}getByInvoiceUuid?invoice_uuid=${invoice_uuid}`);
    }

    public update(li: InvoiceLineItem) {
        return this.http.put<InvoiceLineItem>(`${this.serviceUrl}${li.uuid}`, li);
    }



}