import { Injectable } from '@angular/core';
import states from 'projects/legalreflex-lib/src/assets/states.json';
import countries from 'projects/legalreflex-lib/src/assets/countries.json';
import timezones from 'projects/legalreflex-lib/src/assets/timezones.json';
import { of } from 'rxjs';


export interface Timezone {
  label: string;
  value: string;
  time?: string;
}

export interface Country {
    label: string;
    value: string;
  }
export interface State {
    name: string;
    abbreviation: string;
  }
  
@Injectable()
export class CommonData_Service  {
    public states: State[] = states;
    public countries: Country[] = countries;
    public timezones: Timezone[] = timezones;

    public makeCountryLookupConfig(countryList: Country[] = []) {
      const service = this;
      if(countryList.length){
        countryList.sort((a, b) => {return (a.label > b.label) ? 1 : 0});
        countryList.unshift(countryList.splice(countryList.findIndex(ind => ind.label == "United States of America"), 1)[0]);
        service.countries = countryList;
      }
      let identifier = (country: any) => country && country.value;
      let lookup = (id: string) => of(service.countries.find((country: any) => identifier(country) == id));
      let display = (country: any) => country && country.label;
      let suggest = (term: string) => {
          return of(service.countries.filter((country: any) => {
              return !term || country.label.toLowerCase().includes(term.toLowerCase().trim());
          }));
      };
      return { lookup, display, suggest, identifier };
    }

    public makeStateLookupConfig() {
      const service = this;
      let identifier = (state: any) => state && state.abbreviation;
      let lookup = (id: string) => of(service.states.find((state: any) => identifier(state) == id || id == state.name));
      let display = (state: any) => state && state.name;
      let suggest = (term: string) => {
          return of(service.states.filter((state: any) => {
              return !term || state.name.toLowerCase().includes(term.toLowerCase().trim());
          }));
      };
      return { lookup, display, suggest, identifier };
    }

    public getState(state: string){
      if(state.length == 2){
        let val = this.states.find((statePair)=> statePair.abbreviation == state)
        return val
      } else {
        let val = this.states.find((statePair)=> statePair.name == state)
        return val
      }
    }
}
