import { ResourceModel, Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class FLCompanyBranch extends DexResourceModel {
    @Field(String)
    public address: string = "";
    @Field(String)
    public address2: string = "";
    @Field(String)
    public city: string = "";
    @Field(String)
    public state: string = "";
    @Field(String)
    public zip: string = "";
    @Field(String)
    public phone = "";
    @Field(String)
    public company_uuid : string | null = null;
    @Field(String)
    public activeStatus : string = "";

    @Field(String)
    public check: string = "";

    @Field(String)
    public courtEmail: string = "";

    @Field(String)
    public messengerEmail: string = "";

    @Field(String)
    public processEmail: string = "";

    @Field(String)
    public timezone: string = "";

    @Field(String)
    public afterHoursStart: Date | null=null;

    @Field(String)
    public afterHoursFinish: Date | null=null;

    @Field(String)
    public afterHoursEnabled: boolean =false;

    @Field(String)
    public division: string | null = null;

    @Field(Number)
	public companyNumber: number = 0;

    @Field(String)
    public branchName: string | null = null;

    @Field(String)
    public branchCode: string | null = null;

    public set_Status(){
        if(this.deletedAt == null)
            this.activeStatus = "active";

        else
            this.activeStatus = "inactive"
    }

    public get_Status(){
        return this.activeStatus;
    }
    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    
    get active(): boolean {
        return this.deletedAt == null;
    }
    set active(active: boolean) {
        if (active) {
            this.deletedAt = null;
        } else {
            this.deletedAt = new Date();
        }
    }



}