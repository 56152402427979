import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResourceFormService, ResourceListPane } from '@smartsoftware/reflex-core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatSort } from '@angular/material/sort';
import {
    Notification as Notice,
    Notification_Service,
    NotificationType_Service,
    WIP,
    PagedResourceListComponent,
    NotificationDataSource,
    SystemConfig_service,
    UserDevicePreferencesService
} from 'legalreflex-lib';
import { animate, trigger, state, style, transition } from '@angular/animations';
import { FormArray, FormGroup, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { NotificationPopupDialog } from '../dialog/notificationPopup';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationRestoreDialog } from '../restore/notificationRestoreDialog';

@Component({
    templateUrl: './list.html',
    styleUrls: ['./list.scss'],
    animations: [
        trigger('filterExpand', [
            state('collapsed', style({width: '0px', minWidth: '0'})),
            state('expanded', style({width: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class NotificationList extends PagedResourceListComponent<Notice> {

    @ViewChild(MatSort) sort: MatSort = new MatSort();
    @ViewChild('filter') filterPane: ResourceListPane = new ResourceListPane();
    public filterCollapsed: boolean = sessionStorage.getItem("filterCollapsed") == 'true';

    public WIP : boolean = WIP();

    public accountId: string = ''
    public columnsToDisplay = ['select', 'view', 'subject', 'notificationTypeId', 'createdAt', 'deliveredAt'];
    public noticeTypes: Map<string, string> = new Map<string, string>();
    public isLoading: boolean = true;

    constructor(
        public dataSource: NotificationDataSource,
        public router: Router,
        protected auth: OidcSecurityService,
        protected resourceService: Notification_Service,
        protected notificationTypeService: NotificationType_Service,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        protected resourceFormService: ResourceFormService,
        protected fb: FormBuilder,
        private breadCrumbService: BreadCrumbService,
        public sysConfig: SystemConfig_service,
        public userDevicePreferences: UserDevicePreferencesService
    ) {
        super(dataSource, resourceService, dialog, route, router);
        this.auth.userData$.subscribe(({userData, allUserData}) => {
			let res = userData;
            this.accountId = res.profile.uuid;
        })
        this.filterFormGroup = this.fb.group({
            createdAt_start: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)],
            createdAt_end: [new Date()],
            subject: [''],
            notificationType: [''],
            viewedAt: ['either'],
            source: ['success'],
            deletedAt: ['notDeleted'],
        })
        this.filterFormGroup!.patchValue(this.userDevicePreferences.fcFilter)
        Object.keys(this.dataSource.filters).map((key)=>{this.dataSource.filters[key] = this.userDevicePreferences.fcFilter[key]})

        this.setBreadCrumbs();
    }

    get permissionStatus() {
        return Notification.permission == 'granted';
    }

    requestPermission(){
        Notification.requestPermission().then(()=>{this.setBreadCrumbs()});
    }

    ngOnInit() {
        super.ngOnInit(); 
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        this.dataSource.pageSize = 25;
        this.notificationTypeService.list().subscribe((res)=>{
            res.map((type)=>{
                this.noticeTypes.set(type.id.toString(), type.name);
            })
        })
        this.isLoading = true;

    }

    ngAfterViewInit() {
        super.ngAfterViewInit()
        setTimeout(() => {
            this.filterFormGroup!.valueChanges.subscribe((jsonObject)=>{
                this.userDevicePreferences.fcFilter = jsonObject;
            })
            if (this.dataSource.sort) {
                this.dataSource.sort.active = 'createdAt';
                this.dataSource.sort.direction = 'desc';
            }
            this.filterNotices();
            let filterClick = document.getElementById('filterPaneNotification');
            if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
              filterClick.setAttribute('filterClickAttached','true')
              filterClick.addEventListener("click",()=>{
                  sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
              })
            }
        });
        this.dataSource.loading.subscribe((state)=>{
            this.isLoading = state;
        })
    }

    sortData(event: any){
        this.dataSource.data = this.dataSource.sortData(this.dataSource.data,this.sort);
    }    

    selectionChange(event?:any){
        if(event){
            event.stopPropagation();
        }
        // To fire after selection update goes through
        setTimeout(() => {
            this.setBreadCrumbs();
        })
    }

    setBreadCrumbs(){
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Notifications', url:'/notifications'},
            { label: 'History'}
        ])

        let actions = [];
        actions.push({label:'Refresh', action:this.filterNotices.bind(this,false), icon:'refresh'});
        if(!this.permissionStatus){
            actions.push({ label: 'Turn On push notifications', action: () => this.requestPermission(), isPrimary:true, icon:'' });
        }
        if(this.selection.isEmpty())
            actions.push({label: 'Delete Notices(s)', disabled: true, tooltip:"Please select 1 or more notices.", icon: 'delete'});
        else{
            actions.push({label: 'Delete Notices(s)', action:this.deleteEntries.bind(this), icon: 'delete'});
        }
        this.breadCrumbService.actions.next(actions);
    }

    filterNotices(reset?: boolean){
        if(reset){
            this.filterFormGroup = this.fb.group({
                createdAt_start: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)],
                createdAt_end: [new Date()],
                subject: [''],
                notificationType: [''],
                viewedAt: ['either'],
                source: ['success'],
                deletedAt: ['notDeleted']
            })
        }
        
        let val = this.filterFormGroup?.controls['viewedAt'];
        if(!val || val.value == 'either')
            this.filterFormGroup?.controls['viewedAt'].setValue('either');
    
        this.selection.clear();
        this.setBreadCrumbs();
        this.onFilterSubmit();
    }

    pageChange(event:any){
        this.selection.clear();
        this.setBreadCrumbs();
    }

    openNotification(entity:Notice){
        let dialogRef: MatDialogRef<NotificationPopupDialog>
        dialogRef = this.dialog.open(NotificationPopupDialog, {
            data: entity
        })
        dialogRef.afterClosed().subscribe((res)=>{
            if(res){
                Object.assign(entity, res.data);
                this.resourceService.push(entity).subscribe(()=>{});
            }
        })
    }

    deleteEntries(){
        this.openConfirmDeleteDialog().subscribe(response => {
            if (response.confirmed) {
                let observables = this.selection.selected.map((notice)=>{
                    notice.deletedAt = new Date();
                    notice.isDeleted = true;
                    return this.resourceService.push((notice));
                })
                combineLatest(observables).pipe(
                    tap(_ => {
                        this.selection.clear();
                        this.filterNotices();
                    })
                    )
                    .subscribe({
                        error: error => this.openErrorDialog(error)
                    });
            }
        })
    }

    restoreEntry(entity:Notice, event:Event){
        event.stopPropagation();
        
        this.dialog.open(NotificationRestoreDialog, {
            data: entity
        }).afterClosed().subscribe((res)=>{
            if(res){
                entity.deletedAt = null;
                entity.isDeleted = false;
                this.resourceService.push((entity)).subscribe(()=>{});
            }
        })
    }


}
