import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ResourceService, ReflexEnvironment as ENV } from '@smartsoftware/reflex-core';
import { ResourceListOptions } from '@smartsoftware/reflex-core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CustomerAccountInfo, OrderData, UpdateOrderData } from '../definitions';

import {
    Document,
    Service,
    ServiceCategory,
    Order,
    Notification as Notice
} from '../models/';

import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { merge } from 'rxjs';
import { Page, PageParams, Pager } from '../paged.datasource';

@Injectable()
export class Order_Service extends ResourceService<Order> {
    protected servicePath: string = '/Order';

    public ModelType = Order;

    constructor(
        protected http: HttpClient,
        protected auth: OidcSecurityService,
    ) {
        super(http);
    }

    static makeEmptyFilter(): OrderHistoryUserFilter {
        // object with all possible filters, undefined
        return {
            division: undefined,
            placedByEmail: undefined,
            corpId: undefined,
            requestedBy: undefined,
            orderNumber: undefined,
            orderStatus: undefined,
            negateOrderStatusMatch: undefined,
            orderDate: undefined,
            clientMatterNumber: undefined,
            caseNumber: undefined,
            vendorStatus: undefined,
            displayStatus: undefined,
            serviceType: undefined,
            jobDate: undefined,
            dueDate: undefined,
            subject: undefined,
            deliveryLocation: undefined,
            deliveryAddress: undefined,
            caseName: undefined,
            claimNumber: undefined,
            createdAt: undefined,
            updatedAt: undefined
            // byCorp: DEV NOTE: not filter, flag for decision logic, omitted intentionally
            // byUser: DEV NOTE: not filter, flag for decision logic, omitted intentionally
        };
    }

    public findDocumentsByOrderId(id: string) {
        return this.http.get<Document[]>(`${this.serviceUrl}${id}/Documents`)
            .pipe(map(docs => docs.map(doc => new Document(doc))));
    }

    // testing custom logic for getting order data.
    public historyPage(options: ResourceListOptions = {}, pageParams: PageParams): Observable<Page<Order>> {
        if (pageParams.page <= 0)
            pageParams.page = 1;
        let sources: Observable<Page<Order>>[] = []
        // let sources : Observable<Array<Order>>[] = [];
        let cachePrefix = options.where ? window.btoa(options.where).replace(/=+$/, '') : '';

        if (!options.placedByEmail && options.byUser) {
            console.error('Error: missing email');
            return merge<Page<Order>, Page<Order>>(sources);
        }

        if (!options.corpId && options.byCorp) {
            console.error('Error: missing corpId');
            return merge<Page<Order>, Page<Order>>(sources);
        }

        // object with all possible filters, undefined
        let filters: OrderHistoryUserFilter = Order_Service.makeEmptyFilter();

        // set any of the filters that exist and delete any that were omitted
        Object.keys(filters).map((key: string) => {
            if (options[key]) {
                filters[key as keyof OrderHistoryUserFilter] = options[key];
            }
            if (!filters[key as keyof OrderHistoryUserFilter]) {
                delete filters[key as keyof OrderHistoryUserFilter];
            }
        });

        let requestOptions: any = {
            observe: 'response',
            body: pageParams
        };

        let requestUrl = this.serviceUrl + 'getSiteOrderHistory'

        // Reload from server unless explicitly requested not to
        if (options.reloadFresh !== false) {
            return this.http
                .request
                (
                    'post',
                    requestUrl,
                    requestOptions
                )
                .pipe(
                    map<any, Page<Order>>(
                        response => {
                            if (!response.body.success) {
                                console.error(response.body.message)
                                return { items: [], page: 1, totalItems: [0] };
                            }
                            return response.body.result;
                        }
                    ),
                    tap(
                        // Update the cache for future loads
                        (entities) => { this.listToCache(cachePrefix, entities.items) }
                    )
                )
        }

        return merge<Page<Order>, Page<Order>>(sources);
        // return merge<Order[], Order[]>(...sources);
    }

    public page(params: PageParams, options: ResourceListOptions = {}): Observable<Page<Order>> {
        if (params.page <= 0)
            params.page = 1;
        return this.historyPage(options, params);
    }

    // order history list for public facing site
    // TODO: clean up and combine with other list
    public historyList(options: ResourceListOptions = {}, pageParams: PageParams): Observable<Array<Order>> {
        if (pageParams.page <= 0)
            pageParams.page = 1;
        let sources: Observable<Array<Order>>[] = [];

        let cachePrefix = options.where ? window.btoa(options.where).replace(/=+$/, '') : '';

        // Load the cache unless explicitly requested not to
        if (options.allowCache !== false)
            sources.push(
                this.listFromCache(cachePrefix)
            )

        if (!options.placedByEmail && options.byUser) {
            console.error('Error: missing email');
            return merge<Order[], Order[]>(...sources);
        }

        if (!options.corpId && options.byCorp) {
            console.error('Error: missing corpId');
            return merge<Order[], Order[]>(...sources);
        }

        // object with all possible filters, undefined
        let filters: OrderHistoryUserFilter = Order_Service.makeEmptyFilter();

        // set any of the filters that exist and delete any that were omitted
        Object.keys(filters).map((key: string) => {
            if (options[key]) {
                filters[key as keyof OrderHistoryUserFilter] = options[key];
            }
            if (!filters[key as keyof OrderHistoryUserFilter]) {
                delete filters[key as keyof OrderHistoryUserFilter];
            }
        });

        let requestOptions: any = {
            observe: 'response',
            body: pageParams
        };

        let requestUrl = this.serviceUrl + 'getSiteOrderHistory'

        // Reload from server unless explicitly requested not to
        if (options.reloadFresh !== false) {
            sources.push(
                this.http
                    .request<Order>
                    (
                        'post',
                        requestUrl,
                        requestOptions
                    )
                    .pipe(
                        map<any, Order[]>(
                            response => {
                                if (!response.body.success) {
                                    console.error(response.body.message);
                                    return [] as Order[];
                                }
                                if (response.body.result.items)
                                    return response.body.result.items.map((e: any) => new Order(e))
                                return response.body.result.data.map((e: any) => new Order(e))
                            }
                        ),
                        tap(
                            // Update the cache for future loads
                            (entities) => this.listToCache(cachePrefix, entities)
                        )
                    )
            );
        }

        return merge<Order[], Order[]>(...sources);
    }

    public getByLegacyId(id: string): Observable<Order> {
        let params = new HttpParams().set('id', id);
        return this.http.get<any>(`${this.serviceUrl}getByLegacyId`, { params })
            .pipe(map(o => o as Order));
    }

    // TODO: deprecate or replace
    public list(options: ResourceListOptions = {}): Observable<Order[]> {

        let sources: Observable<Order[]>[] = [];

        let cachePrefix = options.where ? window.btoa(options.where).replace(/=+$/, '') : '';

        // Load the cache unless explicitly requested not to
        if (options.allowCache !== false)
            sources.push(
                this.listFromCache(cachePrefix)
            )

        // Dynamically build request options to send to server
        if (options.accountId) {
            let requestOptions: any = {
                observe: 'response',
                body: {
                    accountId: options.accountId
                }
            };
            // Reload from server unless explicitly requested not to
            if (options.reloadFresh !== false) {
                sources.push(
                    this.http
                        .request<Order>
                        (
                            'post',
                            ENV.config['flapiUrl'] + '/FirstConnect/order/getRecentList',
                            requestOptions
                        )
                        .pipe(
                            map<any, Order[]>(
                                response => {
                                    if (response.body.success) {
                                        return response.body.result.map((e: any) => new Order(e))
                                    }

                                    return [] as Order[];
                                }
                            ),
                            tap(
                                // Update the cache for future loads
                                (entities) => this.listToCache(cachePrefix, entities)
                            )
                        )
                )
            }
        }

        return merge<Order[], Order[]>(...sources);
    }

    public createOrder(orderData: OrderData) {
        return this
            .http
            .post(
                ENV.config['openapiUrl'] + '/order/submit',
                orderData,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        // console.log('TFTEST - order submit response', response);
                        return response.body
                    }
                )
            )
    }

    public processOrder(orderId: string) {
        return this
            .http
            .post(
                ENV.config['openapiUrl'] + '/order/process',
                {
                    order_uuid: orderId
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        // console.log('TFTEST - order process response', response);
                        return response.body
                    }
                )
            )
    }

    public async updateDraftOrder(orderId: string, orderData: UpdateOrderData) {
        return this
            .http
            .post(
                ENV.config['openapiUrl'] + `/order/update/${orderId}`,
                orderData,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        // console.log('TFTEST - documents for draft response', response);
                        return response.body
                    }
                )
            )
    }

    public generateNonDTEmail(orderData: Order, service: string, category: string) {
        return this
            .http
            .post(
                ENV.config['openapiUrl'] + `/order/generateNonDTEmail/`,
                {
                    orderData: orderData,
                    filingService: service,
                    filingCategory: category
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response.body
                    }
                )
            )
    }

    public deleteByOrderIdAndDocumentId(order_uuid: string, document_uuid: string) {
        return this.http.post<any[]>(`${this.serviceUrl}deleteOrderDocument`, { order_uuid, document_uuid });
    }

    public getDatatracInfo(companyNumber: number, controlNumber: string) {
        return this.http.post<any>(ENV.config['openapiUrl'] + `/successconnect/getDatatracInfo`, { companyNumber: companyNumber, controlNumber: controlNumber }, { observe: 'response' })
            .pipe(map(o => o.body.result));
    }

    public getDriverInfo(companyNumber: number, controlNumber: number, operation: 'getObject' | 'putObject' = 'getObject') {
        let params = new HttpParams().set('operation', operation);
        return this.http.get<any>(ENV.config['openapiUrl'] + `/cops/order-driver?companyNo=${companyNumber}&controlNo=${controlNumber}`, { params })
            .pipe(map(o => o));
    }

    // Tristar FRR account number = "999-" + [wincopys.firstlegal.io\ws7].FirstReproV8.dbo.Customer.Code
    // public getTristarAccounts(corpId: string) : Promise<CustomerAccountInfo[]> {
    //     return this
    //         .http
    //         .get(
    //             ENV.config['openapiUrl'] + '/tristar/customer?corpId=' + corpId,
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) => {
    //                     if(response.body.success) { 
    //                         let accounts: CustomerAccountInfo[] = response.body.result.map((account:any) => {
    //                             let accountData : CustomerAccountInfo = {
    //                                 system: 'Tristar', // Records
    //                                 corpId: (account.CorpIDNumber.trim()) ?? corpId,
    //                                 companyNo: 999, // hard value for Tristar Records
    //                                 customerNo: account.Code ? account.Code.trim() : '',
    //                                 name: account.Name ? account.Name.trim() : '',
    //                                 street: account.Address1 ? account.Address1.trim() : '',
    //                                 room: account.Address2 ? account.Address2.trim() : '',
    //                                 city: account.City ? account.City.trim() : '',
    //                                 state: account.State ? account.State.trim() : '',
    //                                 zip: account.Zip ? account.Zip.trim() : '',
    //                                 country: '',
    //                                 accountNumber: account.Code ? ('999-' + account.Code.trim()) : ''
    //                             }

    //                             return accountData;
    //                         });

    //                         return accounts;
    //                     }

    //                     return [] as CustomerAccountInfo[]
    //                 }
    //             )
    //         )
    //         .toPromise();
    // }

    // Tristar FLDS account number = "995-" + [wincopys.firstlegal.io\ws7].FirstReproV8.dbo.Customer.Code
    // public getTristarDigitalAccounts(corpId: string) : Promise<CustomerAccountInfo[]> {
    //     return this
    //         .http
    //         .get(
    //             ENV.config['openapiUrl'] + '/firstdigital/customer?corpId=' + corpId,
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) => {
    //                     if(response.body.success) { 
    //                         let accounts: CustomerAccountInfo[] = response.body.result.map((account:any) => {
    //                             let accountData : CustomerAccountInfo = {
    //                                 system: 'Tristar FDS', // Digital
    //                                 corpId: account.CorpIDNumber ? account.CorpIDNumber.trim() : corpId,
    //                                 companyNo: 995, // hard value for Tristar Digital
    //                                 customerNo: account.Code ? account.Code.trim() : '',
    //                                 name: account.Name ? account.Name.trim() : '',
    //                                 street: account.Address1 ? account.Address1.trim() : '',
    //                                 room: account.Address2 ? account.Address2.trim() : '',
    //                                 city: account.City ? account.City.trim() : '',
    //                                 state: account.State ? account.State.trim() : '',
    //                                 zip: account.Zip ? account.Zip.trim() : '',
    //                                 country: '',
    //                                 accountNumber: account.Code ? ('995-' + account.Code.trim()) : ''
    //                             }

    //                             return accountData;
    //                         });

    //                         return accounts;
    //                     }

    //                     return [] as CustomerAccountInfo[]
    //                 }
    //             )
    //         )
    //         .toPromise();
    // }

    // Tristar FLI (Investigations) account number = "993-" + [wincopys.firstlegal.io\ws7].FirstReproV8.dbo.Customer.Code
    // public getTristarInvestigationAccounts(corpId: string) : Promise<CustomerAccountInfo[]>{
    //     return this
    //         .http
    //         .get(
    //             ENV.config['openapiUrl'] + '/investigations/customer?corpId=' + corpId,
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) => {
    //                     if(response.body.success) { 
    //                         let accounts: CustomerAccountInfo[] = response.body.result.map((account:any) => {
    //                             let accountData : CustomerAccountInfo = {
    //                                 system: 'Tristar FLI', // Investigations
    //                                 corpId: account.CorpIDNumber ? account.CorpIDNumber.trim() : corpId,
    //                                 companyNo: 993, // hard value for Tristar Investigations
    //                                 customerNo: account.Code ? account.Code.trim() : '',
    //                                 name: account.Name ? account.Name.trim() : '',
    //                                 street: account.Address1 ? account.Address1.trim() : '',
    //                                 room: account.Address2 ? account.Address2.trim() : '',
    //                                 city: account.City ? account.City.trim() : '',
    //                                 state: account.State ? account.State.trim() : '',
    //                                 zip: account.Zip ? account.Zip.trim() : '',
    //                                 country: '',
    //                                 accountNumber: account.Code ? ('993-' + account.Code.trim()) : ''
    //                             }

    //                             return accountData;
    //                         });

    //                         return accounts;
    //                     }

    //                     return [] as CustomerAccountInfo[]
    //                 }
    //             )
    //         )
    //         .toPromise();
    // }

    // Solaria (FLDS) account number = "997-" + Solaria.dbo.Client.ClientID
    // public getSolariaAccounts(corpId: string) : Promise<CustomerAccountInfo[]>{
    //     return this
    //         .http
    //         .get(
    //             ENV.config['openapiUrl'] + '/solaria/client?corpId=' + corpId,
    //             {
    //                 observe: 'response'
    //             }
    //         )
    //         .pipe(
    //             map(
    //                 (response: HttpResponse<any>) => {
    //                     if(response.body.success) { 
    //                         let accounts: CustomerAccountInfo[] = response.body.result.map((account:any) => {
    //                             let accountData : CustomerAccountInfo = {
    //                                 system: 'Acclaim', // Solaria Depositions
    //                                 corpId: account.DUNS ? account.DUNS.trim() : corpId,
    //                                 companyNo: 997, // hard value for Depositions
    //                                 customerNo: account.ClientID ?? '',
    //                                 name: account.Name ? account.Name.trim() : '',
    //                                 // street: account.Address1 ?? '',
    //                                 // room: account.Address2 ?? '',
    //                                 // city: account.City ?? '',
    //                                 // state: account.State ?? '',
    //                                 // zip: account.Zip ?? '',
    //                                 // country: '',
    //                                 accountNumber: account.ClientID ? ('997-' + account.ClientID) : ''
    //                             }

    //                             return accountData;
    //                         });

    //                         return accounts;
    //                     }

    //                     return [] as CustomerAccountInfo[]
    //                 }
    //             )
    //         )
    //         .toPromise();
    // }

    // Datatrac account number = cops_reporting_db.cops_reporting.customers.company_no + "-" + cops_reporting_db.cops_reporting.customers.customer_no
    // DEV NOTE: this is the old way of getting customer accounts directly from COPS, 
    //  instead we use clientBranchService.getgetCustomerAccountsByCorpId
    public getDatatracAccounts(corpId: string): Promise<CustomerAccountInfo[]> {
        return this
            .http
            .get(
                ENV.config['openapiUrl'] + '/cops/customer?corpId=' + corpId,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        if (response.body.success) {
                            let accounts: CustomerAccountInfo[] = response.body.result.map((account: any) => {
                                let accountData: CustomerAccountInfo = {
                                    system: 'Datatrac', // COPS
                                    corpId: account.corporate_id ? account.corporate_id.trim() : '',
                                    companyNo: account.company_no ?? '',
                                    customerNo: account.customer_no ?? '',
                                    name: account.customer_name ? account.customer_name.trim() : '',
                                    attention: account.attention ? account.attention.trim() : '',
                                    street: account.local_addr1 ? account.local_addr1.trim() : '',
                                    room: account.local_room ? account.local_room.trim() : '',
                                    city: account.local_city ? account.local_city.trim() : '',
                                    state: account.local_state ? account.local_state.trim() : '',
                                    zip: account.local_zip_postal ? account.local_zip_postal.trim() : '',
                                    country: account.local_country ? account.local_country.trim() : '',
                                    phone: account.phone ? account.phone.trim() : '',
                                    phoneExt: account.phoneExt ? account.phoneExt.trim() : '',
                                    accountNumber: (account.company_no && account.customer_no)
                                        ? (account.company_no + '-' + account.customer_no) : ''
                                }

                                return accountData;
                            });

                            return accounts;
                        }

                        return [] as CustomerAccountInfo[]
                    }
                )
            )
            .toPromise();
    }

    // TODO: Update this
    public finalizeOrder(finalizeData: any) {
        return this
            .http
            .post(
                ENV.config['flapiUrl'] + '/OpenAPI/order/submit',
                {
                    orderId: finalizeData.orderId
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (response: HttpResponse<any>) => {
                        return response.body;
                    }
                )
            );
    }
}

/**
 * Making a copy of the order service since we need the non overridden push method, so the 
 * ResourceViewComponent can handle the save method.
 */
@Injectable()
export class AdminOrder_Service extends ResourceService<Order> implements Pager<Order> {
    protected servicePath: string = '/Order';

    public ModelType = Order;

    constructor(
        protected http: HttpClient,
    ) {
        super(http);
    }

    page(params: PageParams): Observable<Page<Order>> {
        return this.history(params);
    }

    public history(pageParams: PageParams) {

        let params = new HttpParams().append('params', btoa(JSON.stringify(pageParams)));
        return this.http.get<Page<Order>>(`${this.serviceUrl}`, {
            params
        }).pipe(map(page => {
            page.items = page.items.map(order => new Order(order));
            return page;
        }));

    }

    public delete(order: Order) {
        return this.http.delete<Order>(`${this.serviceUrl}${order.uuid}`)
            .pipe(map(o => new Order(o)));
    }

    public findDocumentsByOrderId(id: string) {
        return this.http.get<Document[]>(`${this.serviceUrl}${id}/Documents`)
            .pipe(map(docs => docs.map(doc => new Document(doc))));
    }

    public addDocumentToOrder(order: Order, document: Document) {
        return this.http.post<Document>(`${this.serviceUrl}${order.uuid}/Documents`, document)
            .pipe(map(doc => new Document(doc)));
    }

    public updateDocument(order: Order, document: Document) {
        return this.http.put<Document>(`${this.serviceUrl}${order.uuid}/Documents/${document.uuid}`, document)
            .pipe(map(doc => new Document(doc)));
    }

    public getDocumentSignedURL(orderId: string, documentId: string, operation: 'getObject' | 'putObject' = 'getObject') {
        let params = new HttpParams().set('operation', operation);
        return this.http.get<any>(`${this.serviceUrl}${orderId}/Documents/${documentId}`, { params })
            .pipe(map(o => o.message as string));
    }
    public getDatatracInfo(companyNumber: number, controlNumber: string) {
        return this.http.post<any>(ENV.config['openapiUrl'] + `/successconnect/getDatatracInfo`, { companyNumber: companyNumber, controlNumber: controlNumber }, { observe: 'response' })
            .pipe(map(o => o.body.result));
    }

    public getDriverInfo(companyNumber: number, controlNumber: number, operation: 'getObject' | 'putObject' = 'getObject') {
        let params = new HttpParams().set('operation', operation);
        return this.http.get<any>(ENV.config['openapiUrl'] + `/cops/order-driver?companyNo=${companyNumber}&controlNo=${controlNumber}`, { params })
            .pipe(map(o => o));
    }

    public manualDocumentNotice(order: Order, documents: Document[], cc: string[], message: string) {
        return this.http.post(ENV.config['openapiUrl'] + '/order/document/notice', { order: order, documents: documents, cc: cc, message: message }, { observe: 'response' })
            .pipe(map(doc => new Document(doc)));
    }

    public makeLookupConfig() {
        const service = this;
        let identifier = (type: Order) => type && type.uuid;
        let lookup = (id: string) => service.get(id);
        let display = (type: Order) => {
            if (!type)
                return '';
            let words = [type.orderNumber, type.caseName].filter(w => !!w);
            return words.join(' - ');
        };
        let suggest = (term: string, limit?: number) => {
            if (!term)
                return of([]);

            return service.history({
                page: 1,
                pageSize: limit || 50,
                sortColumn: 'orderNumber',
                sortOrder: 'asc',
                filters: {
                    orderNumber: term
                }
            }).pipe(
                map(page => {
                    return page.items;
                })
            );
        }
        return { identifier, lookup, display, suggest };
    }

    public deleteByOrderIdAndDocumentId(order_uuid: string, document_uuid: string) {
        return this.http.post<any[]>(`${this.serviceUrl}deleteOrderDocument`, { order_uuid, document_uuid });
    }

    public getOrdersByInvoiceUuid(invoice_uuid: string) {
        return this.http.get<any[]>(`${this.serviceUrl}getOrdersByInvoiceUuid?invoice_uuid=${invoice_uuid}`);
    }
}

// DEV NOTE: any changes here should be reflected in the makeEmptyFilter function
export interface OrderHistoryUserFilter {
    division?: Array<string>,
    placedByEmail?: string,
    corpId?: string,
    requestedBy?: string,
    orderNumber?: string,
    orderStatus?: Array<string> | string,
    negateOrderStatusMatch?: boolean,
    orderDate?: Array<Date>,
    clientMatterNumber?: string,
    caseNumber?: string,
    vendorStatus?: string,
    displayStatus?: string,
    serviceType?: string,
    jobDate?: Array<Date>,
    dueDate?: Array<Date>,
    subject?: string,
    deliveryLocation?: string,
    deliveryAddress?: string,
    caseName?: string,
    claimNumber?: string
    createdAt?: Array<Date>,
    updatedAt?: Array<Date>,
    byCorp?: boolean
    byUser?: boolean
}
