import { Component, Inject, ViewChild } from '@angular/core';
import {Sort} from '@angular/material/sort';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms'
import { 
    SuccessAccount, 
    SuccessAccount_Service, 
    SuccessAccountSettings, 
    UserPermission_Service, 
    VendorAccount_Service, 
    validators, 
    NotificationPreferencesComponent,
    SpecialInstructionPreferencesComponent,
    ChangePasswordComponent,
    SuccessAccountSettingsService,
    SuccessAccountPermissionNode_Service,
    SuccessAccountRole_Service,
    Dictionary_Service,
    SystemConfig_service,
    SuccessAccountPermissionNodeName,
    Role,
    PermissionNode,
    RolePermissionNode,
    Role_Service,
    SuccessAccountPermissionNode,
    SuccessAccountRole,
    PermissionNode_Service,
    RolePermissionNode_Service,
    ClientBranch,
    FLCompanyBranch_Service,
    ClientBranch_Service,
    Client_Service,
    FLCompanyBranch
} from 'legalreflex-lib';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, debounceTime, delay, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { ResourceEditComponent, ResourceFormService } from '@smartsoftware/reflex-core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { DataSource } from '@angular/cdk/collections';
import { DatatracFailureDialog } from './datatracFailure';

@Component({
    templateUrl: './view.html',
    styleUrls: ['./view.scss']
})
export class UserView extends ResourceEditComponent<SuccessAccount, SuccessAccount_Service>  {

    @ViewChild(NotificationPreferencesComponent) notificationPreferences?: NotificationPreferencesComponent;
    @ViewChild(SpecialInstructionPreferencesComponent) specialInstructions?: SpecialInstructionPreferencesComponent;
    @ViewChild(ChangePasswordComponent) changePasswordComponent?: ChangePasswordComponent;
    @ViewChild('roleSelectionList') roleSelectionList?: MatSelectionList;
    @ViewChild('permissionSelectionList') permissionSelectionList?: MatSelectionList;

    public settings : SuccessAccountSettings = new SuccessAccountSettings();
    public isCurrentUser: boolean = true;
    public newUser: boolean = false;
    public initialUser: SuccessAccount | null = null;
    public USERNAME_HINT: string = `Can only contain uppercase letters, lowercase letters, numbers, and special characters: . @ _ -  and cannot start or end with special characters.`
    public companyNumberSuggestions?: Observable<string[]>;
    public customerNumberSuggestions?: Observable<ClientBranch[]>;
    public companyNumbers?: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public branches?: BehaviorSubject<ClientBranch[]> = new BehaviorSubject<ClientBranch[]>([]);
    public companyNumberFocus: Subject<FocusEvent> = new Subject();
    public customerNumberFocus: Subject<FocusEvent> = new Subject();

    // all the permission assignments for each role
    public rolePermissions: RolePermissionNode[] = [];

    // all the roles for clients
    public roles: Role[] = [];
    public roleSource: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([]);

    // all the permissions for clients
    public permissions: PermissionNode[] = [];
    public permissionSource: BehaviorSubject<PermissionNode[]> = new BehaviorSubject<PermissionNode[]>([]);

    // roles that this user has been assigned
    public usersRoles: Role[] = [];
    public userRolesMapping: SuccessAccountRole[] = [];

    // role and permission list changes trigger permission unions
    public initial: boolean = false;
    public currentPermissions: PermissionNode[] = [];
    public currentRoles: Role[] = [];

    // permission that have been assigned to this user as overrides
    public accountPermissions: PermissionNode[] = [];
    public overridePermissions: SuccessAccountPermissionNode[] = [];


    constructor(
        protected entityService: SuccessAccount_Service,
        // indirectly call syncUser by injecting the service, to ensure it gets called
        private userPermissionService: UserPermission_Service,
        private successAccountPermissionService: SuccessAccountPermissionNode_Service,
        private successAccountRoleService: SuccessAccountRole_Service,
        private successAccountSettingsService: SuccessAccountSettingsService,
        private rolePermissionNodeService: RolePermissionNode_Service,
        private roleService: Role_Service,
        private permissionNodeService: PermissionNode_Service,
        private vendorAccountService: VendorAccount_Service,
        private flCompanyBranchService: FLCompanyBranch_Service,
        private clientBranchService: ClientBranch_Service,
        private clientService: Client_Service,
        private authService: OidcSecurityService,
        private fb : FormBuilder,
        private snackBar: MatSnackBar,
        private breadCrumbService: BreadCrumbService,
        protected pageTitleService: Title,
        protected route: ActivatedRoute,
        protected dialog: MatDialog,
        protected resourceFormService: ResourceFormService<SuccessAccount>,
        public dictionary: Dictionary_Service,
        public sysConfig: SystemConfig_service
    ) {
        super(entityService, route, dialog, resourceFormService);

		this.pageTitleService.setTitle("FirstConnect - Profile");
		  
        this.breadCrumbService.breadcrumbs.next([
            { label: 'Users', url: '/users'},
            { label: 'Loading' }
        ]);

        this.breadCrumbService.actions.next([
            { label: 'Save', action: () => this.save(), isPrimary: true, icon: ''}
        ]);
    }
    
    ngOnInit(): void {
        super.ngOnInit();
        if(this.sysConfig.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.authService.logoff();
        };
        let showSettings: any = "";

        this.entityStatus.subscribe((entity) => {
            if(!entity || !entity.id) return;

            this.breadCrumbService.breadcrumbs.next([
                { label: 'Users', url: '/users'},
                { label: `${entity.firstName} ${entity.lastName}` }
            ]);

            if(this.newUser){
                this.newUser = false;
                this.setupRolePermissions();
                this.settings.successAccount_uuid = this.entity.uuid;
                this.successAccountSettingsService.push(this.settings).subscribe(settings => {
                    this.settings = settings;
                    this.snackBar.open('Saved', undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' });
                });
            } else {
                this.successAccountSettingsService.getByAccountUUID(entity.uuid).subscribe(
                    (settings) => {
                        this.settings = settings;
                        showSettings = settings; 
                        // issue for swapping between logged in users profile and different user
                        if(this.initialUser && entity.id != this.initialUser.uuid){
                            this.initial = false;
                            this.ngAfterViewInit();
                        }
                        if(!this.initialUser) this.initialUser = entity;
                    }
                );
            }
            
            this.authService.isAuthenticated$.subscribe((authenticated) => {
                this.authService.userData$.subscribe((user)=>{
                    if(this.entity && user.userData.profile.uuid != this.entity.uuid) {
                        this.isCurrentUser = false;
                    } else if (this.entity && user.userData.profile.uuid == this.entity.uuid){ 
                        this.isCurrentUser = true;
                    }
                })
                if(!authenticated) return;
            });
        });

        this.route.url.subscribe((url)=>{
            if(url[0].path == 'new'){
                this.entityForm = this.resourceFormService.from(new SuccessAccount());
                this.newUser = true;
                this.entity = new SuccessAccount();
                this.entity.id = ''
                this.authService.isAuthenticated$.subscribe((authenticated) => {
                    this.authService.userData$.subscribe((user)=>{
                        if(user.userData.profile.corpId) {
                            this.breadCrumbService.breadcrumbs.next([
                                { label: 'Users', url: '/users'},
                                { label: 'New User' }
                            ]);
                    
                            this.isCurrentUser = false;
                            let profile = user.userData.profile
                            let corpIdControl = this.entityForm.get('corpId');
                            let companyNumberControl = this.entityForm.get('companyNumber');
                            let customerNumberControl = this.entityForm.get('customerNumber');
    
                            corpIdControl?.setValidators([Validators.required]);
                            if (corpIdControl && companyNumberControl)
                                companyNumberControl.setValidators([validators.dependsOn(corpIdControl), Validators.required, Validators.min(1), Validators.pattern(/^[0-9]*$/)]);
                            if (companyNumberControl && customerNumberControl && corpIdControl)
                                customerNumberControl.setValidators([validators.dependsOn(companyNumberControl, corpIdControl), Validators.required, Validators.min(1), Validators.pattern(/^[0-9]*$/)]);
                            
                            this.companyNumberFocus.pipe(
                                filter(e => {
                                    return e.type == 'focusout'
                                }),
                                switchMap(() => {
                                    let companyNumberControl = this.entityForm.get('companyNumber');
                                    let corpIdControl = this.entityForm.get('corpId');
                                    return this.clientBranchService.findByCompanyNumberAndCorpId(companyNumberControl?.value, corpIdControl?.value)
                                        .pipe(catchError(() => of([])));;
                                })
                            ).subscribe(suggestions => {
    
                                this.branches?.next(suggestions);
                                if (suggestions.length == 1) {
                                    let [ b ] = suggestions;
                                    let customerNumberControl = this.entityForm.get('customerNumber');
                                    customerNumberControl?.setValue(b.customerNumber);
                                }
                            });
    
                            
                            let companyNumberValidation: Subject<string> = new BehaviorSubject<string>('');
                            let companyNumberValidationPipe = companyNumberValidation.pipe(
                                debounceTime(400),
                                map(term => {
                                    // will have all the companies that were fetched by corpid
                                    let companyNumber = this.companyNumbers?.value.find(c => `${c}` == term);
                                    // don't return validation error, but set warning
                                    (companyNumberControl as any).warnings = !companyNumber ? { companyNumber: true } : null;
                                    return null;
                                    // return company ? null : { companyNumber: true };
                                }),
                                take(1)
                            );
                            companyNumberControl?.setAsyncValidators([
                                (control: AbstractControl): Observable<ValidationErrors | null> => {
                                    companyNumberValidation.next(control.value);
                                    return companyNumberValidationPipe;
                                }
                            ])
                
                            let customerNumberValidation: Subject<string> = new BehaviorSubject<string>('');
                            let customerNumberValidationPipe = customerNumberValidation.pipe(
                                debounceTime(400),
                                map(term => {
                                    // will have all the branches that were fetched by company number
                                    let branch = this.branches?.value.find(b => `${b.customerNumber}` == term);
                                    // don't return validation error, but set warning
                                    (customerNumberControl as any).warnings = !branch ? { customerNumber: true } : null;
                                    
                                    if(branch)
                                        this.entityForm.get('primaryClientBranch_uuid')?.setValue(branch.uuid);
                                    else
                                        this.entityForm.get('primaryClientBranch_uuid')?.setValue(null);
                                    return null;
                                }),
                                take(1)
                            );
                            customerNumberControl?.setAsyncValidators([
                                (control: AbstractControl): Observable<ValidationErrors | null> => {
                                    customerNumberValidation.next(control.value);
                                    return customerNumberValidationPipe;
                                }
                            ])
    
                            corpIdControl?.setValue(profile.corpId);
                            companyNumberControl?.setValue(profile.companyNumber);
                            customerNumberControl?.setValue(profile.customerNumber);
    
                            if (corpIdControl?.value) {
                                this.flCompanyBranchService.getDistinctCompanyNumbersByCorpId(corpIdControl?.value)
                                    .subscribe(numbers => this.companyNumbers?.next(numbers));
                            }
                            if (companyNumberControl?.value && corpIdControl?.value) {
                                this.clientBranchService.findByCompanyNumberAndCorpId(companyNumberControl?.value, corpIdControl.value)
                                    .subscribe(branches => this.branches?.next(branches));
                            }

                            this.companyNumberSuggestions = companyNumberControl?.valueChanges.pipe(
                                startWith(''),
                                debounceTime(400),
                                map(term => {
                                    return (this.companyNumbers?.value || []).filter(number => {
                                        return `${number}`.includes(term);
                                    });
                                })
                            );
                
                            this.customerNumberSuggestions = customerNumberControl?.valueChanges.pipe(
                                startWith(''),
                                debounceTime(400),
                                map(term => {
                                    return (this.branches?.value || []).filter(branch => {
                                        return `${branch.customerNumber}`.includes(term);
                                    });
                                })
                            );
                            let uniqueUserNameValidator = validators.uniqueResource(this.entityService, this.entity, "username");
                            let uniqueEmailValidator = validators.uniqueResource(this.entityService, this.entity, "email");
                            this.entityForm.get('username')?.setAsyncValidators([uniqueUserNameValidator]);
                            this.entityForm.get('email')?.setAsyncValidators([uniqueEmailValidator]);
                
                            this.entityForm.get('username')?.setValidators([
                                Validators.required,
                                Validators.minLength(2),
                                Validators.maxLength(50),
                                Validators.pattern(/^[A-Za-z0-9][A-Za-z0-9._@-]*[A-Za-z0-9]$/)
                            ]);
                            this.entityForm.get('firstName')?.setValidators([Validators.required,this.nonEmptyValidator()])
                            this.entityForm.get('lastName')?.setValidators([Validators.required,this.nonEmptyValidator()])
                        };
                    })
                    if(!authenticated) return;
                });
            }
        })
        // Workaround to load preferences for the user when the page loads.
        this.settings = showSettings;
    }


    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.setupRolePermissions();
    }

    setupRolePermissions(){
        this.entityStatus.pipe(filter(e => !!e), take(1)).subscribe(e => {
            if(!this.entity)
                return;

            let allPermissions = this.permissionNodeService.getClientPermissionNodes()
                .pipe(filter(ls => !!ls), take(1));
            let allRoles = this.roleService.getClientRoles()
                .pipe(filter(ls => !!ls), take(1));
            let allRolePermissions = this.rolePermissionNodeService.list({allowCache:false, where:{filters:{deletedAt: { operator: 'IS', value: null }}}})
                .pipe(filter(ls => !!ls), take(1));


            let permissionOverrides: Observable<SuccessAccountPermissionNode[]> = of([])
                .pipe(filter(ls => !!ls), take(1));
            let accountRoles: Observable<SuccessAccountRole[]> = of([])
                .pipe(filter(ls => !!ls), take(1));
            
            if(this.entity.uuid){
                permissionOverrides = this.successAccountPermissionService.list({allowCache:false, where:{filters:{successAccount_uuid:this.entity.uuid, deletedAt: { operator: 'IS', value: null }}}})
                .pipe(filter(ls => !!ls), take(1));
                accountRoles = this.successAccountRoleService.getSuccessAccountRolesForAccount(this.entity.uuid)
                .pipe(filter(ls => !!ls), take(1));
            }

            combineLatest([allRoles, accountRoles, allPermissions, permissionOverrides, allRolePermissions])
                .subscribe({ 
                    next: ([roles, userRolesMapping, allPermissions, permissionOverrides, allRolePermissions]) => {
                    if(this.roleSelectionList) {
                        allPermissions = allPermissions.sort((a,b) => a.code > b.code ? 1 : (a.code < b.code  ? -1 : 0))
                        this.roleSelectionList.selectionChange.subscribe(change => {
                            for (let option of change.options) {
                                let rolePermissions = this.union_permission_sets([option.value]);
                                let others = this.permissionSelectionList?.options.filter(option => rolePermissions.includes(option.value));
                                if (!others)
                                    continue;
                                // find the MatSelectOptions that map to each rolePermissions
                                if (option.selected) {
                                    // go through each permission in role
                                    for (let other of others) { 
                                        // if not checked => check
                                        if (!other.selected) {
                                            other.selected = true;
                                        }
                                        // if other is selected or not, disable, since other is in the selected role's permission set
                                        other.disabled = true;
                                    }
                                }
                                else {
                                    let selected = this.roleSelectionList?.selectedOptions.selected.map(o => o.value) as Role[];
                                    // find union of permissions sets of each selected role 
                                    let permissions = this.union_permission_sets(selected);
                                    // go through each permission in role 
                                    for (let other of others) {
                                        // if not in the union set => uncheck
                                        if (!permissions.includes(other.value)) {
                                            other.selected = false;
                                            other.disabled = false;
                                        }
                                    }
                                }
                            }
                        });
                    }
                    // let rolesPreEdit = userRolesMapping.map(ur => roles.find(r => r.uuid == ur.role_uuid)).filter(r => !!r) as Role[];
                    // rolesPreEdit.filter((r:Role)=>{return !r.name.includes('Beta Access')})
                    this.usersRoles = userRolesMapping.map((ur) => roles.find((r:Role) => r.uuid == ur.role_uuid)).filter(r => !!r) as Role[];
                    this.userRolesMapping = userRolesMapping;
                    this.rolePermissions = allRolePermissions;

                    this.overridePermissions = permissionOverrides;
                    this.accountPermissions = permissionOverrides.map(op => allPermissions.find(p => p.uuid == op.permissionNode_uuid)).filter(p => !!p) as PermissionNode[];
                    
                    // Can't edit own role/permissions
                    this.roleSource.next(this.usersRoles);
                    this.permissionSource.next(this.accountPermissions);
                    // this is changing the options selected during the same change detection cycle, so we delay
                    this.roleSelectionList?.options.changes.pipe(delay(0)).subscribe(options => {
                        if (options) {
                            for (let role of this.usersRoles) {
                                for (let option of options) {
                                    if (option.value.uuid == role.uuid) {
                                        option.selected = true;
                                        break;
                                    }
                                }
                            }
                        }
                    })
                    // this is changing the options selected during the same change detection cycle, so we delay
                    this.permissionSelectionList?.options.changes.pipe(delay(0)).subscribe((options: MatListOption[]) => {
                        if (options) {
                            //union of overrides and the union of all the user role's permission sets!
                            let overrides = this.overridePermissions
                                .map(sap => this.permissions.find(p => p.uuid == sap.permissionNode_uuid))
                                .filter(p => !!p) as PermissionNode[];

                            // only difference is the overrides shouldn't be disabled
                            for (let perm of overrides) {
                                for (let option of options) {
                                    if (option.value.uuid == perm.uuid) {
                                        option.selected = true;
                                        break;
                                    }
                                }
                            }
                            // permissions inherited through user roles, selected ones need to be disabled
                            let tempRoles : Role[] = []
                            this.roleSelectionList?.selectedOptions.selected.map((selectedOption)=>{
                                let role = this.roles.find((role)=>{ selectedOption.value.uuid == role.uuid})
                                if(role){
                                    tempRoles.push(role);
                                }
                            })
                            if(!this.initial){
                                this.initial = true;
                                tempRoles = this.usersRoles;
                            }
                            for (let perm of this.union_permission_sets(tempRoles)) {
                                for (let option of options) {
                                    if (option.value.uuid == perm.uuid) {
                                        option.selected = true;
                                        option.disabled = true;
                                        break;
                                    }
                                }
                            }
                        }
                    });
                    // these assignments will trigger the option.changes above
                    this.roles = roles;
                    this.roles.sort((a: Role, b: Role) => this.stringCompare(a.name, b.name));
                    this.permissions = allPermissions;
                    this.currentRoles = this.roles;
                    if(this.entity.internalUser){
                        this.currentPermissions = this.permissions;
                    }
                    else{
                        this.currentPermissions = this.permissions.filter((permission)=>{return !permission.internal});
                    }
                    if(this.initialUser && this.entity.id != this.initialUser.uuid){
                        if(this.notificationPreferences) {
                            this.notificationPreferences.settings = this.settings
                            this.notificationPreferences.resetForm()
                            this.notificationPreferences.initForm()
                            this.notificationPreferences.notificationStrings = []
                            this.notificationPreferences.mapCCsString()
                        }
                        if(this.specialInstructions) {
                            this.specialInstructions.settings = this.settings
                            this.specialInstructions.initForm();
                        };
                        this.initialUser = this.entity
                    }

            },
            error: err => {
                let message = err.error?.error || 'Something went wrong retrieving role and permission lists';
                this.snackBar.open(message, undefined, {duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error'})
                console.error(err);
            }, complete: () => {
                // Disable/hide role and permission options that shouldn't be available for logged in editor
                setTimeout(()=>{
                    if(!this.can_manage_user()){
                        if(this.roleSelectionList){
                            let notFound = this.roleSelectionList.options.filter((roleOption) => {
                                return !this.userPermissionService.loggedInUserRoles.find((editorRole)=>{
                                    return editorRole.name == roleOption.value.name
                                })
                            }) 
                            notFound.map((role)=> role.disabled = true)
                        }

                        if(this.permissionSelectionList){
                            let entries = Object.entries(this.userPermissionService.loggedInUserPermissionNodes).map((entry)=> entry[0])
                            // String cast to regular string
                            let cat: string[] = this.union_permission_sets(this.userPermissionService.loggedInUserRoles)
                                .map((permission)=> {return permission.code as string});
                            cat.map((c)=>{
                                if(!entries.find(e => e == c))
                                    entries.push(c)
                            })
                            let notFound = this.permissionSelectionList.options.filter((allPermissionNode) => {
                                return !entries.find((editorPermission)=>{
                                    return editorPermission == allPermissionNode.value.code
                                })
                            }) 
                            notFound.map((permission)=> permission.disabled = true)
                        }
                    }
                })
            }})
        })
    }

    get companyNumberControl(): WarnFormControl | undefined {
        return this.entityForm.get('companyNumber') as WarnFormControl | undefined;
    }

    get customerNumberControl(): WarnFormControl | undefined {
        return this.entityForm.get('customerNumber') as WarnFormControl | undefined;
    }

    nonEmptyValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value.trim().length == 0) {return {required: {value: true}};}
            return null;
        }
    }
    
    passwordValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.entity.uuid && control.value.trim().length == 0) {return {required: {value: true}};}
            return null;
        }
    }

    getCompanyNumber(c: FLCompanyBranch | string | number) {
        if (typeof c === 'string')
            return c;
        if (typeof c === 'number')
            return `${c}`;
        return `${c.companyNumber}`;
    }

    getCustomerNumber(b: ClientBranch | string | number) {
        if (typeof b === 'string')
            return b;
        if (typeof b === 'number')
            return `${b}`;
        return `${b.customerNumber}`;
    }

    can_manage_user(){
        return this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_firm_users)
        || this.userPermissionService.canDo(SuccessAccountPermissionNodeName.manage_all_users);
    }

    stringCompare = (a: string, b: string) => {
        if ((!a && b) || a < b) return -1;
        if ((a && !b) || a > b) return 1;
        return 0;
    }
    
    union_permission_sets(roles: Role[]): PermissionNode[] {
        let result = new Set<PermissionNode>();
        if(roles.length == 0)
            return [];
        for (let role of roles) {
            let rolePerms = this.rolePermissions.filter(rp => rp.role_uuid == role.uuid);
            let perms = [];
            if(!this.entity.internalUser){
                perms = this.currentPermissions.filter(p => (!!rolePerms.find(rp => rp.permissionNode_uuid == p.uuid) && !p.internal));
            } else {
                perms = this.currentPermissions.filter(p => !!rolePerms.find(rp => rp.permissionNode_uuid == p.uuid));
            }
            for (let p of perms)
                result.add(p);
        }
        return Array.from(result);
    }

    save() {
 
        if(!this.entity || !this.entity.id){
            let newAccount = new SuccessAccount();
            this.settings = new SuccessAccountSettings();
            newAccount.email = this.entityForm.controls['email'].value;
            newAccount.firstName = this.entityForm.controls['firstName'].value
            newAccount.lastName = this.entityForm.controls['lastName'].value
            newAccount.customerNumber = this.entityForm.controls['customerNumber'].value;
            newAccount.companyNumber = this.entityForm.controls['companyNumber'].value;
            newAccount.vendorCallerID = this.entityForm.controls['vendorCallerID'].value;

            let customer = this.entityForm.get('customerNumber')?.value;
            let branch = this.entityForm.get('companyNumber')?.value;
            let corp = this.entityForm.get('corpId')?.value;
            
            this.clientBranchService.findByCompanyNumberCustomerNumberAndCorpId(branch, customer, corp).subscribe((res)=>{
                this.entityService.setupFrequentCaller(newAccount).subscribe((response:any)=>{
                    console.debug("Caller response:", response);
                    if(response.success){
                        this.fullSave();
                    }else{
                        this.snackBar.open(response.errorMessage, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                        this.dialog.open(DatatracFailureDialog, {
                            data: response.errorMessage ? response.errorMessage : response
                        }).afterClosed().subscribe((result)=>{return});
                    }
                })
            })
        } else {
            this.fullSave();
        }
    }

    fullSave(){
        super.save();

        // this will save the main entity
        if (this.entity.uuid) {
            let os: any[] = [];
            let id = this.entity.uuid;
            if(!id) {
                let email = this.entityForm.controls['email'].value;
                let name = this.entityForm.controls['firstName'].value + ' ' + this.entityForm.controls['lastName'].value;
                let username = this.entityForm.controls['username'].value;
                let password = this.entityForm.controls['password'].value;
                os.push(this.entityService.sendAccountCreateEmail({name, email, username, password}));
            };
            this.notificationPreferences?.bindFormValuesToSettings();
            this.specialInstructions?.bindFormValuesToSettings();
            this.settings.successAccount_uuid = this.entity.uuid;
            os.push(this.successAccountSettingsService.push(this.settings))
            if(!this.isCurrentUser){
                os.push(this.save_permission_overrides(this.entity))
                os.push(this.save_roles(this.entity))
            }
            combineLatest(os).subscribe({
                next: () => {
                    this.snackBar.open(`Saved ${this.entity.username}`, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                }, 
                error: err => {
                    let message = err.error?.error || 'Something went wrong saving the user';
                    this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                    console.error(err);
                }
            })
        }
    }

    save_permission_overrides(user: SuccessAccount) {
        // all the selected permissions. Either manually selected or selected through a role.
        let selectedPermissions: PermissionNode[] = this.permissionSelectionList?.selectedOptions.selected.map(o => o.value) as PermissionNode[];
        if(!selectedPermissions) 
            selectedPermissions = [];

        let selectedRoles: Role[] = this.roleSelectionList?.selectedOptions.selected.map(option => option.value as Role) as Role[];
        if(!selectedRoles) 
            selectedRoles = [];
        // contains the permissions that are inherited through the role selection
        let unionPermissions: PermissionNode[] = this.union_permission_sets(selectedRoles);

        // the manually added permission overrides are not in the union of all the selected roles permission sets
        let manualPermissions = selectedPermissions.filter(p => !!!unionPermissions.find(other => other.uuid == p.uuid));

        let existingPermissions = this.overridePermissions
            .map(sap => this.permissions.find(p => p.uuid == sap.permissionNode_uuid))
            .filter(p => !!p) as PermissionNode[];
        let deletions = existingPermissions.filter(p => !!!manualPermissions.find(other => other.uuid == p.uuid));
        let additions = manualPermissions.filter(p =>   !!!existingPermissions.find(other => other.uuid == p.uuid));
        let updates = selectedPermissions.filter(p => !!existingPermissions.find(other => other.uuid == p.uuid));
        let observables: Observable<SuccessAccountPermissionNode>[] = [];

        for (let permission of additions) {
            let o = this.successAccountPermissionService.push(new SuccessAccountPermissionNode({
                permissionNode_uuid: permission.uuid,
                successAccount_uuid: user.uuid
            }));
            observables.push(o);
        }

        for (let permission of deletions) {
            let toDelete = this.overridePermissions.find(ap => ap.permissionNode_uuid == permission.uuid);
            if (!toDelete)
                continue;
            toDelete.deletedAt = new Date();
            let o = this.successAccountPermissionService.push(toDelete);
            observables.push(o);
        }

        for (let permission of updates) {
            let toUpdate = this.overridePermissions.find(ap => ap.permissionNode_uuid == permission.uuid);
            if (!toUpdate)
                continue;
            toUpdate.updatedAt = new Date();
            let o = this.successAccountPermissionService.push(toUpdate);
            observables.push(o);
        }

        if (!observables.length) {
            console.log('no permissions to update');
            return of(observables);
        }

        let result = combineLatest(observables);

        return result.pipe(
            tap(results => {
                console.log('all permission updates completed', results);
                this.overridePermissions = results.filter(ap => !!(additions.find(o => o.uuid == ap.permissionNode_uuid) || updates.find(o => o.uuid == ap.permissionNode_uuid)));
                this.accountPermissions = selectedPermissions;
        }));
    }

    save_roles(user: SuccessAccount) {

        let selected: Role[] = this.roleSelectionList?.selectedOptions.selected.map(option => option.value as Role) as Role[];
        if(!selected) 
            selected = [];
        let existing: Role[] = this.usersRoles;

        let additions = selected.filter(p => !!!existing.find(other => other.uuid == p.uuid));
        let deletions = existing.filter(p => !!!selected.find(other => other.uuid == p.uuid));
        let updates   = selected.filter(p =>  !!existing.find(other => other.uuid == p.uuid));
        let observables: Observable<SuccessAccountRole>[] = [];

        for (let role of additions) {
            let o = this.successAccountRoleService.push(new SuccessAccountRole({
                role_uuid: role.uuid,
                successAccount_uuid: user.uuid
            }));
            observables.push(o);
        }

        for (let role of deletions) {
            let userRoles = this.userRolesMapping.filter(ur => ur.role_uuid == role.uuid); // find any duplicate assignments as well
            if (!userRoles.length) {
                console.warn(`Could not find exsiting role ${role.uuid} on user ${user.uuid} to delete`);
                continue;

            } 
            for (let userRole of userRoles) {
                userRole.id = userRole.uuid;
                userRole.deletedAt = new Date();
                let deletedRole = new SuccessAccountRole(userRole)
                let o = this.successAccountRoleService.push(deletedRole);
                observables.push(o);
            }
        }

        for (let role of updates) {
            let userRole = this.userRolesMapping.find(ur => ur.role_uuid == role.uuid);
            if (!userRole) {
                console.warn(`Could not find exsiting role ${role.uuid} on user ${user.uuid} to update`);
                continue;
            }
            userRole.id = userRole.uuid;
            userRole.updatedAt = new Date();
            let updatedRole = new SuccessAccountRole(userRole)
        let o = this.successAccountRoleService.push(updatedRole);
            observables.push(o);
        }

        if (!observables.length) {
            console.debug('no roles to update');
            return of(observables);
        }
        let result = combineLatest(observables);
        return result.pipe(
            tap(results => {
                console.log('all role updates completed', results);
                this.usersRoles = selected;
                this.userRolesMapping = results.filter(ur => {
                    return !!(additions.find(r => r.uuid == ur.role_uuid) || updates.find(r => r.uuid == ur.role_uuid));
                })
            })
        );
    }

/* Pulled from old preferences.ts
    saveNotificationSettings() {
        if (this.settings) {
            this.notificationPreferences?.bindFormValuesToSettings();
            this.successAccountService.saveSettings(this.settings)
                .subscribe({
                    next: settings => {
                        this.snackBar.open('Preferences Saved', undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                    }, 
                    error: err => {
                        let message = err.error?.error || 'Something went wrong saving the preferences';
                        this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                        console.error(err);
                    }
                });
        }
    }

    saveSpecialInstructions() {
        if (this.settings) {
            this.specialInstructions?.bindFormValuesToSettings();
            this.successAccountService.saveSettings(this.settings)
                .subscribe({
                    next: settings => {
                        this.snackBar.open('Preferences Saved', undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                    }, 
                    error: err => {
                        let message = err.error?.error || 'Something went wrong saving the preferences';
                        this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
                        console.error(err);
                    }
                });
        }
    }
*/
}

class WarnFormControl extends FormControl {
    warnings?: ValidationErrors | null = null;
}