


import { Injectable } from "@angular/core";
import { ResourceService } from "@smartsoftware/reflex-core";
import { HttpClient } from '@angular/common/http'
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SuccessAccount, Territory } from "../models";
import { SuccessAccount_Service } from "./successAccount.service";
// import { SuccessAccount } from "legalreflex-lib";

@Injectable()
export class Territory_Service extends ResourceService<Territory>
{
    protected servicePath: string = '/Territory';
    public ModelType = Territory;

    constructor( protected http: HttpClient, router: Router, account: SuccessAccount_Service )
    { 
        super(http); 
    }
}